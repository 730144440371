import { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { PageContainer } from '../ui/PageComponents';
import BreadCrumbs from '../BreadCrumbs';
import { PEProgressBar } from '../linear-progress';
import { ErrorAlert } from '../alerts';
import ApiClient from '../../ApiClient';
import SetupStatusContainer from '../SetupStatusContainer';
import { observer } from 'mobx-react';
import { reaction, when } from 'mobx';
import { useHistory } from 'react-router';
import { UserStore } from '../..';

const PAGE_TITLE = 'Venue Setup & Status';

const VenueSetupStatusPage = () => {
  const history = useHistory();
  const [venuesOptions, setVenuesOptions] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>({
    value: '',
    label: '',
  });
  const [error, setError] = useState<any>('');
  const [loading, setLoading] = useState(true);

  const [venueData, setVenueData] = useState<{
    commCenAppIconUrl: string;
    nameKeyStr: string;
    displayName: string;
    userCheckWebsiteText: string;
    stateLocation: string;
  }>();
  const fetchValidVenues = async () => {
    setError('');
    try {
      const res = await ApiClient.getValidVenues();
      if (res?.data?.validVenues?.length > 0) {
        setVenuesOptions(res?.data?.validVenues);
        return;
      }
      setError('Something wrong getting venues');
    } catch (err) {
      console.log(err);
      setError('Something wrong getting venues');
    } finally {
      setLoading(false);
    }
  };

  const fetchSelectedVenueData = async () => {
    setLoading(true);
    try {
      const res = await ApiClient.getVenueSetupStatusData(selected.value);
      if (res?.data?.status) {
        setVenueData(res.data.status);
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const userStore = useContext(UserStore);
  const pollingTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined,
      () => {
        history.push('/login');
      }
    );

    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push('/login');
        } else {
          fetchValidVenues();
        }
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  useEffect(() => {
    fetchSelectedVenueData();
  }, [selected]);

  return (
    <PageContainer>
      <BreadCrumbs
        routes={[
          { route: '/home', name: 'Home' },
          { route: '/venue-setup-status', name: PAGE_TITLE },
        ]}
      />
      {loading && <PEProgressBar />}
      {error && <ErrorAlert message={error} />}

      <div className="text-left mt-10 text-gray-700 dark:text-gray-400">
        <p className="mb-2 font-normal text-2xl">
          {venuesOptions.length} VENUES FOUND
        </p>
        <p className="mb-2 font-normal text-sm italic">
          *select a venue below to setup
        </p>
        <div>
          {' '}
          <Select
            value={selected}
            options={venuesOptions.map(({ displayName, venueId }) => ({
              value: venueId,
              label: `${displayName} (${venueId})`,
            }))}
            onChange={(newValue) => setSelected(newValue)}
          />
        </div>
      </div>
      <div>
        {!loading && venueData && (
          <SetupStatusContainer
            venue={selected}
            venueData={venueData}
            fetchSelectedVenueData={fetchSelectedVenueData}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default observer(VenueSetupStatusPage);
