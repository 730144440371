import { useContext, Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SummaryStore, UserStore } from '../../index';
import { VenuesDropMenu } from './VenuesDropMenu';
import { CommandCentreVenue } from '../../state/UserState';

export const NavMenu = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const logout = () => {
    userStore.logout();
    history.push('/');
  };

  const selectVenue = (venue: CommandCentreVenue) => {
    userStore.selectedVenue = venue;
    userStore.venueSearchTerm = '';
    summaryStore.changeVenue();
  };

  const updateSearchTerm = (venueSearchTerm: string) => {
    userStore.venueSearchTerm = venueSearchTerm;
  };

  const { selectedVenue, userVenues, userVenuesAlphabetical, venueSearchTerm } =
    userStore;

  useEffect(() => {
    userStore.refreshUserVenues();
  }, []);

  return (
    <Fragment>
      <nav className="bg-gray-100 border-gray-200 dark:bg-gray-900 border-b-2">
        <div className="flex flex-wrap justify-between mx-auto max-w-screen-xl p-4">
          <div className="flex items-center text-primary-900 p-1">
            <img
              className="cursor-pointer"
              style={{ height: 44, cursor: 'pointer', borderRadius: 12 }}
              src={
                'https://ok2p-test-bucket.s3.ap-southeast-2.amazonaws.com/ok2play_logo_dark.png'
              }
              onClick={() => history.push('/home')}
            />
            <div className="text-2xl font-semibold text-black ml-2">
              <p style={{ marginBottom: -16 }}>Command Centre</p>
            </div>
          </div>

          <div className="flex items-center gap-x-4 p-0 m-0 ">
            {selectedVenue && (
              <VenuesDropMenu
                isSingleVenue={
                  userVenues === undefined || userVenues?.length <= 1
                }
                selectedVenue={selectedVenue}
                userVenues={userVenuesAlphabetical}
                totalVenueCount={userVenues?.length ?? 0}
                selectVenue={selectVenue}
                venueSearchTerm={venueSearchTerm}
                updateVenueSearchTerm={updateSearchTerm}
              />
            )}

            {userStore.user && (
              <Fragment>
                <p className="text-gray-900 m-0">
                  Welcome, {`${userStore.user?.firstName}`}
                </p>

                <p
                  onClick={logout}
                  className="text-gray-900 dark:text-white hover:underline cursor-pointer hover:text-primary-600 m-0"
                >
                  <FontAwesomeIcon icon={faSignOut} /> Logout
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </nav>
    </Fragment>
  );
});
