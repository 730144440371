import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';

import { LoginFormComponent } from '@kimoycatayas/pe-ui-kit-test';
import { useHistory } from 'react-router';
import { UserStore } from '../..';
import ApiClient from '../../ApiClient';
import { PEProgressBar } from '../linear-progress';
import { PageContainer } from '../ui/PageComponents';

const LoginPage = observer(() => {
  const userStore = useContext(UserStore);
  const history = useHistory();

  const [loginInProgress, setLoginInProgress] = useState(false);

  const handleSubmit = (email: string, password: string) => {
    setLoginInProgress(true);

    ApiClient.userLogin(email, password)
      .then((response: any) => {
        userStore.login(response.data.user, response.data.commandCentreVenues);
        setLoginInProgress(false);
        history.push('/select-venue');
      })
      .catch((error: any) => {
        console.log(error);
        setLoginInProgress(false);
      });
  };

  const {
    selectedVenue,
    isLoggedIn,
    userVenues,
    userVenuesAlphabetical,
    venueSearchTerm,
  } = userStore;

  useEffect(() => {
    if (selectedVenue && isLoggedIn) {
      history.push('/home');
    }

    if (isLoggedIn) {
      history.push('/select-venue');
    }
  }, []);

  if (loginInProgress) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <PEProgressBar />
      </div>
    );
  }

  if (!isLoggedIn) {
    return (
      <LoginFormComponent
        handleSubmit={handleSubmit}
        title={'OK2PLAY'}
        subtitle={'Command Centre'}
        color="white"
        backgroundColor={'#E24A3B'}
        imageUrl={
          'https://ok2p-test-bucket.s3.ap-southeast-2.amazonaws.com/ok2play_logo_dark.png'
        }
      />
    );
  }

  if (isLoggedIn && userVenuesAlphabetical?.length === 0) {
    return (
      <PageContainer>
        {userVenuesAlphabetical?.length === 0 && (
          <div css={{ textAlign: 'center', fontSize: 16, marginBottom: 4 }}>
            No Venues found
          </div>
        )}
      </PageContainer>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <PEProgressBar />
    </div>
  );
});

export default LoginPage;
