import { useEffect, useState } from 'react';
import { PELinearProgressWithLabel } from '../components/linear-progress';
import UploadImageContainer from '../components/UploadImageContainer';
import ApiClient from '../ApiClient';

const SetupStatusContainer = ({
  venue,
  venueData,
  fetchSelectedVenueData,
}: {
  venue: {
    value: string;
    label: string;
  };
  venueData: {
    commCenAppIconUrl: string;
    nameKeyStr: string;
    displayName: string;
    userCheckWebsiteText: string;
    stateLocation: string;
  };
  fetchSelectedVenueData: () => void;
}) => {
  const [localVenueData, setLocalVenueData] = useState(venueData);
  const [updatedVenueData, setUpdatedVenueData] = useState(venueData);

  useEffect(() => {
    setLocalVenueData(venueData);
  }, [venueData]);

  return (
    <div>
      <div className="mt-5">
        <p className="w-full flex gap-2 items-center font-medium text-xl text-gray-700 dark:text-gray-400">
          Configure Setup and Status for: {venue.label}
        </p>
        <p className="w-full flex gap-2 items-center font-medium text-xl text-gray-700 dark:text-gray-400">
          Venue ID: {venue.value}
        </p>
      </div>

      <div>
        <VenueIcon
          venueId={parseInt(venue.value)}
          logoImgUrl={localVenueData.commCenAppIconUrl}
          fetchSelectedVenueData={fetchSelectedVenueData}
        />

        {/* <VenueBackground
          appBackgroundImgUrl={localVenueData.appBackgroundImgUrl}
          fetchSelectedVenueData={fetchSelectedVenueData}
        /> */}

        <div className="my-4 block w-full p-6 bg-gray-100 border border-gray-200 rounded-lg shadow">
          <p className="w-full flex gap-2 items-center font-normal text-gray-700 dark:text-gray-400">
            Name Key Str:
            <span>
              <input
                value={updatedVenueData.nameKeyStr}
                // onChange={(e) =>
                //   setUpdatedVenueData({
                //     ...updatedVenueData,
                //     nameKeyStr: e.target.value,
                //   })
                // }
                onInput={(e) => {
                  // Filter the input to only allow lowercase letters and underscores
                  const value = e.currentTarget.value.replace(/[^a-z_]/g, '');
                  setUpdatedVenueData((prev) => ({
                    ...prev,
                    nameKeyStr: value,
                  }));
                }}
                className="w-max p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-300"
              />
            </span>
            {updatedVenueData.nameKeyStr !== localVenueData.nameKeyStr && (
              <SaveCancelBUtton
                handleSave={() => {
                  ApiClient.updateVenue({
                    venueId: parseInt(venue.value),
                    NameKeyStr: updatedVenueData.nameKeyStr,
                  }).then((res: any) => {
                    if (res.data.success) {
                      setLocalVenueData((prev) => ({
                        ...prev,
                        nameKeyStr: updatedVenueData.nameKeyStr,
                      }));
                    }
                  });
                }}
                handleCancel={() => {
                  setUpdatedVenueData((prev) => ({
                    ...prev,
                    nameKeyStr: localVenueData.nameKeyStr,
                  }));
                }}
              />
            )}
          </p>
        </div>

        <div className="my-4 block w-full p-6 bg-gray-100 border border-gray-200 rounded-lg shadow">
          <p className="w-full flex gap-2 items-center font-normal text-gray-700 dark:text-gray-400">
            Display Name:{' '}
            <span>
              <input
                value={updatedVenueData.displayName}
                onChange={(e) =>
                  setUpdatedVenueData({
                    ...updatedVenueData,
                    displayName: e.target.value,
                  })
                }
                className="w-max p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-300"
              />
            </span>
            {updatedVenueData.displayName !== localVenueData.displayName && (
              <SaveCancelBUtton
                handleSave={() => {
                  ApiClient.updateVenue({
                    venueId: parseInt(venue.value),
                    DisplayName: updatedVenueData.displayName,
                  }).then((res: any) => {
                    if (res.data.success) {
                      setLocalVenueData((prev) => ({
                        ...prev,
                        displayName: updatedVenueData.displayName,
                      }));
                    }
                  });
                }}
                handleCancel={() => {
                  setUpdatedVenueData((prev) => ({
                    ...prev,
                    displayName: localVenueData.displayName,
                  }));
                }}
              />
            )}
          </p>
        </div>

        <div className="my-4 block w-full p-6 bg-gray-100 border border-gray-200 rounded-lg shadow">
          <p className="w-full flex gap-2 items-center font-normal text-gray-700 dark:text-gray-400">
            User Check Website Text:{' '}
          </p>
          <span>
            <textarea
              value={updatedVenueData.userCheckWebsiteText}
              // onChange={(e) =>
              //   setUpdatedVenueData({
              //     ...updatedVenueData,
              //     userCheckWebsiteText: e.target.value,
              //   })
              // }
              className="mt-2 w-full min-h-36 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-300"
            />
          </span>

          {/* {updatedVenueData.userCheckWebsiteText !==
            localVenueData.userCheckWebsiteText && (
            <SaveCancelBUtton
              handleSave={() => {
                ApiClient.updateVenue({
                  venueId: parseInt(venue.value),
                  UserCheckWebsiteText: updatedVenueData.userCheckWebsiteText,
                }).then((res: any) => {
                  if (res.data.success) {
                    setLocalVenueData((prev) => ({
                      ...prev,
                      userCheckWebsiteText:
                        updatedVenueData.userCheckWebsiteText,
                    }));
                  }
                });
              }}
              handleCancel={() => {
                setUpdatedVenueData((prev) => ({
                  ...prev,
                  userCheckWebsiteText: localVenueData.userCheckWebsiteText,
                }));
              }}
            />
          )} */}
        </div>

        <div className="my-4 block w-full p-6 bg-gray-100 border border-gray-200 rounded-lg shadow">
          <p className="w-full flex gap-2 items-center font-normal text-gray-700 dark:text-gray-400">
            State Location:{' '}
            <span>
              <select
                value={updatedVenueData.stateLocation}
                onChange={(e) =>
                  setUpdatedVenueData({
                    ...updatedVenueData,
                    stateLocation: e.target.value,
                  })
                }
                className="ml-2 w-32 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-300"
              >
                <option value="NSW">NSW</option>
                <option value="VIC">VIC</option>
                <option value="QLD">QLD</option>
                <option value="WA">WA</option>
              </select>
            </span>{' '}
            {updatedVenueData.stateLocation !==
              localVenueData.stateLocation && (
              <SaveCancelBUtton
                handleSave={() => {
                  ApiClient.updateVenue({
                    venueId: parseInt(venue.value),
                    StateLocation: updatedVenueData.stateLocation,
                  }).then((res: any) => {
                    if (res.data.success) {
                      setLocalVenueData((prev) => ({
                        ...prev,
                        stateLocation: updatedVenueData.stateLocation,
                      }));
                    }
                  });
                }}
                handleCancel={() => {
                  setUpdatedVenueData((prev) => ({
                    ...prev,
                    stateLocation: localVenueData.stateLocation,
                  }));
                }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SetupStatusContainer;

const VenueIcon = ({
  venueId,
  logoImgUrl,
  fetchSelectedVenueData,
}: {
  venueId: number;
  logoImgUrl: string;
  fetchSelectedVenueData: () => void;
}) => {
  const [iconFile, setIconFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [percentCompleted, setPercentCompleted] = useState(0);

  const handleSave = async () => {
    setLoading(true);
    try {
      const s3Key = await ApiClient.getSingleUploadPresignedS3Url({
        file: iconFile,
      });

      if (s3Key) {
        await ApiClient.updateVenue({
          venueId,
          VenueLogoS3Key: s3Key,
        });

        setTimeout(() => {
          fetchSelectedVenueData();
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      setError('Error saving icon');
    } finally {
      setLoading(false);
      setIconFile(null);
      setPercentCompleted(0);
      setError('');
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setError('');

      const blobUrl = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobUrl;
      img.onload = () => {
        setIconFile(file);
        return;
      };
    }
  };

  return (
    <div className="w-full">
      <div className="my-4 block w-full p-6 bg-gray-100 border border-gray-200 rounded-lg shadow">
        <div className={'flex flex-row gap-2 justify-center'}>
          <div className="w-full flex flex-col items-center justify-center gap-4">
            <p className="mb-2 flex gap-2 items-center font-normal text-gray-700 dark:text-gray-400">
              Venue Icon:
            </p>
            <img
              src={logoImgUrl}
              alt="logo image url"
              className="w-max h-40 object-contain rounded-lg shadow"
            />
          </div>

          <div className="w-full flex flex-col">
            <UploadImageContainer
              id="iconFile"
              label="Upload New Icon"
              blobUrl={iconFile && URL.createObjectURL(iconFile)}
              errorMessage={error}
              handleFileChange={handleFileChange}
              description="Required Ratio: 1:1"
            />

            {!loading && iconFile && (
              <button
                onClick={handleSave}
                type="button"
                className="mt-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Save New Icon
              </button>
            )}
            {loading && (
              <div className="mt-4">
                <PELinearProgressWithLabel value={percentCompleted} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const VenueBackground = ({
  appBackgroundImgUrl,
  fetchSelectedVenueData,
}: {
  appBackgroundImgUrl: string;
  fetchSelectedVenueData: () => void;
}) => {
  const [backgroundFile, setBackgroundFile] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [percentCompleted, setPercentCompleted] = useState(0);

  const handleSave = async () => {
    setLoading(true);
    try {
      // const presignedUrlResponse = await getSingleUploadPresignedS3Url({
      //   imageFileName: backgroundFile.name,
      // });
      // if (presignedUrlResponse) {
      //   const uploadUrl = presignedUrlResponse.data.imageUploadUrl;
      // Step 2: Upload the file to S3 using the presigned URL
      // await uploadToS3PresignedUrl({
      //   uploadUrl: uploadUrl,
      //   data: backgroundFile,
      //   progressCallback: (percentCompleted) => {
      //     console.log(`Upload progress: ${percentCompleted}%`);
      //     setPercentCompleted(percentCompleted);
      //   },
      // });
      // await saveVenueAppBackground(presignedUrlResponse.data.imageKey);
      //   setTimeout(() => {
      //     fetchSelectedVenueData();
      //   }, 3000);
      // }
    } catch (err) {
      console.log(err);
      setError('Error saving background');
    } finally {
      setLoading(false);
      setBackgroundFile(null);
      setPercentCompleted(0);
      setError('');
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setError('');

      const blobUrl = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobUrl;
      img.onload = () => {
        if (img.width <= img.height) {
          setError('Background image should be in landscape orientation');
          return;
        }
        setBackgroundFile(file);
      };
    }
  };

  return (
    <div className="w-full">
      <div className="my-4 block w-full p-6 bg-gray-100 border border-gray-200 rounded-lg shadow">
        <div className={'flex flex-row gap-2 justify-center'}>
          <div className="w-full flex flex-col items-center justify-center gap-4">
            <p className="flex gap-2 items-center font-normal text-gray-700 dark:text-gray-400">
              Venue Background:
            </p>
            <img
              src={appBackgroundImgUrl}
              alt="background image url"
              className="w-full h-40 object-cover rounded-lg"
            />
          </div>

          <div className="w-full flex flex-col">
            <UploadImageContainer
              id="backgroundFile"
              label="Upload New Background"
              blobUrl={backgroundFile && URL.createObjectURL(backgroundFile)}
              errorMessage={error}
              handleFileChange={handleFileChange}
              description="Required Ratio: 16:9"
            />

            {!loading && backgroundFile && (
              <button
                onClick={handleSave}
                type="button"
                className="mt-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Save New Icon
              </button>
            )}
            {loading && (
              <div className="mt-4">
                <PELinearProgressWithLabel value={percentCompleted} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SaveCancelBUtton = ({
  handleSave,
  handleCancel,
}: {
  handleSave: () => void;
  handleCancel: () => void;
}) => {
  return (
    <div className="ml-6 flex flex-row justify-end gap-2">
      <button
        onClick={handleSave}
        type="button"
        className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        Save
      </button>
      <button
        onClick={handleCancel}
        type="button"
        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
      >
        Cancel
      </button>
    </div>
  );
};
