import { makeAutoObservable, runInAction } from "mobx";
import ApiClient from "../ApiClient";
import { RequestActionTaken } from "../components/pages/cases/CaseDetailsPage";

export type AlertLevels = "OK" | "DELAYED" | "WARNING";
export type HelpRequestStatus =
  | "COMPLETE"
  | "INCOMPLETE"
  | "REOPENED"
  | "HANDED_TO_THIRD_PARTY";
export type CreationType = "USER_ID" | "MOBILE" | "INCIDENT" | "AUTOMATIC";
export type RequestSources =
  | "INCIDENT_APP"
  | "MOBILE"
  | "FACIAL_ID"
  | "QR_WEBSITE"
  | "KIOSK"
  | "THREE_HOUR_GAMBLING"
  | "COMMAND_CENTRE"
  | "GENERAL_API"
  | "RESPONDER_APP";

export type UserHelpRequests = {
  detectedFaceImgUrl: null | string;
  attachments: any[];
  helpRequestId: number;
  creationType: CreationType;
  content: string;
  customActions: any[];
  incidentReasons: any[];
  incidentReason: any;
  actionStatus: HelpRequestStatus;
  requestSource: RequestSources;
  actingFromSource: RequestSources;
  requesterName?: string;
  siteLocation?: string;
  machineNumber?: string;
  personDescription?: string;
  notes: any[];
  requestInitiatedTime: number;
  timeCompleted: number;
  timeCreated: number;

  actingStaffMemberName: string;
  requesterFirstName?: string;
  requesterLastName?: string;
  requesterMobileNumber?: string;
  requesterEmailAddress?: string;
  requesterHelpKind?: string;
  requesterMemberNumber?: string;
  requesterS3ImageFileName?: string;

  requesterCaseCount: number;

  // local values
  secondsSince: number;
  alertLevel?: AlertLevels;
};

export type HelpRequestActionLogItem = {
  requestActionLogId: number;
  helpRequestId: number;

  actionType: string;
  actionStatus: RequestActionTaken;
  actionNote: string;
  actionStatusDisplayText: string;

  actionDisplayText: string;

  timeCreated: number;
};

export type AlertReceivers = {
  alertReceiverId?: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
};

export type StaffResponderAccounts = {
  venueStaffUserId?: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;

  userHasSetup: boolean;
};

export type StaffUserAccounts = {
  venueStaffUserId?: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;

  userHasSetup: boolean;
};

export type StringSettingType = {
  venueStringSettingId?: number;
  venueId?: number;
  stringContent?: string;
  listType?: string;
  timeCreated?: number;
  category?: string;
};

export type HelpRequestActions = "CLOSE_CASE" | "OPEN_CASE";

export default class SummaryState {
  todaysRequestsCount: number = 0;
  previousDayRequestsCount: number = 0;
  last28DaysRequestsCount: number = 0;

  IncidentReasonCategory: any;
  averageResponseTimeSecs?: number = undefined;

  venueClosingTime: number | null = null;
  caseSearchFilterTime: number | null = null;

  devicesCount?: number = undefined;
  loggedInDevicesCount: number = 0;

  alertReceivers: AlertReceivers[] = [];
  staffResponderAccounts: StaffResponderAccounts[] = [];
  staffUserAccounts: StaffUserAccounts[] = [];

  incidentReasons: StringSettingType[] = [];
  customActions: StringSettingType[] = [];
  siteLocations: StringSettingType[] = [];

  searchCaseResults: UserHelpRequests[] = [];
  caseSearchTerm = "";

  dataLoaded = false;

  todaysRequests: any[] = [];
  previousDayRequests: any[] = [];
  last28DaysRequests: any[] = [];

  private pollingTimer: NodeJS.Timeout | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  resetCaseSearch() {
    this.caseSearchTerm = "";
    this.searchCaseResults = [];
  }

  deleteStaffAccount(venueStaffUserId: number) {
    this.staffResponderAccounts = this.staffResponderAccounts.filter(
      (user) => user.venueStaffUserId !== venueStaffUserId
    );
  }

  deleteIncidentReason(id: number) {
    this.incidentReasons = this.incidentReasons.filter(
      (user) => user.venueStringSettingId !== id
    );
  }

  deleteCustomAction(id: number) {
    this.customActions = this.customActions.filter(
      (user) => user.venueStringSettingId !== id
    );
  }

  deleteSiteLocation(id: number) {
    this.siteLocations = this.siteLocations.filter(
      (user) => user.venueStringSettingId !== id
    );
  }

  deleteAlertReceiver(alertReceiverId: number) {
    this.alertReceivers = this.alertReceivers.filter(
      (receiver) => receiver.alertReceiverId !== alertReceiverId
    );
  }

  startHomeDataPolling = () => {
    if (this.pollingTimer != undefined) return;

    this.pollingTimer = setInterval(() => {
      this.refreshHomeData();
    }, 10000);
  };

  changeVenue = () => {
    this.dataLoaded = false;

    this.averageResponseTimeSecs = undefined;

    this.venueClosingTime = null;
    this.caseSearchFilterTime = null;

    this.devicesCount = undefined;
    this.loggedInDevicesCount = 0;

    this.alertReceivers = [];
    this.staffResponderAccounts = [];
    this.staffUserAccounts = [];

    this.searchCaseResults = [];
    this.caseSearchTerm = "";

    this.refreshHomeData();
  };

  refreshHomeData() {
    ApiClient.getIncidentsOverview()
      .then((response: any) => {
        runInAction(() => {
          const cases = response.data;
          this.todaysRequests = cases.todaysRequests;
          this.previousDayRequests = cases.previousDayRequests as any;
          this.last28DaysRequests = cases.last28DaysRequests as any;
        });
      })
      .finally(() => {
        this.dataLoaded = true;
      });

    ApiClient.getHomeSummary()
      .then((response: any) => {
        runInAction(() => {
          this.IncidentReasonCategory = response.data.IncidentReasonCategory;
          this.averageResponseTimeSecs = response.data.averageResponseTimeSecs;
          this.alertReceivers = response.data.alertReceivers;
          this.staffResponderAccounts = response.data.staffMemberAccounts;
          this.staffUserAccounts = response.data.staffUserAccounts;
          this.incidentReasons = response.data.features?.incidentReasons;
          this.customActions = response.data.features?.customActions;
          this.siteLocations = response.data.features?.siteLocations;
          this.devicesCount = response.data.devicesCount;
          this.loggedInDevicesCount = response.data.loggedInDevicesCount;
          this.venueClosingTime = response.data.venueClosingTime;
          this.caseSearchFilterTime = response.data.caseSearchFilterTime;
        });
      })
      .catch((error) => {});
  }
}

export const summaryState = new SummaryState();
