import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction, when } from 'mobx';
import { PageContainer } from '../../ui/PageComponents';
import ApiClient from '../../../ApiClient';
import { GoldBallLoader } from '../../ui/Loading';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import Select from 'react-select';
import * as EmailValidator from 'email-validator';
import { CcUserListItem, CcUserType } from './CcUserListItem';
import BreadCrumbs from '../../BreadCrumbs';

export type ValidVenue = {
  venueId: number;
  displayName: string;
};

export type SelectedOptionType = {
  value: number;
  label: string;
};

export const CcAccountsAdminPage = observer(() => {
  const userStore = useContext(UserStore);
  const history = useHistory();
  const pollingTimer = useRef<NodeJS.Timeout>();

  const [loading, setLoading] = useState(true);

  const [validVenues, setValidVenues] = useState<ValidVenue[] | undefined>(
    undefined
  );
  const [selectedOptions, setSelectedOptions] = useState<OnChangeValue<
    SelectedOptionType,
    true
  > | null>(null);

  const [isAddNewAccountMode, setIsAddNewAccountMode] = useState(false);
  const [createUserSuccess, setCreateUserSuccess] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const [ccUsers, setCcUsers] = useState<CcUserType[] | undefined>(undefined);
  const [filterString, setFilterString] = useState('');
  const [displayUsers, setDisplayUsers] = useState<CcUserType[] | undefined>(
    undefined
  );

  const localInitialState = {
    userId: undefined,
    firstName: '',
    lastName: '',
    emailAddress: '',
  };

  const [localCcUser, setLocalCcUser] = useState(localInitialState);

  const [requestInProgress, setRequestInProgress] = useState(false);

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined || !userStore.isAdmin,
      () => {
        history.push('/login');
      }
    );

    reaction(
      () => userStore.isLoggedIn,
      (isLoggedIn) => {
        if (!isLoggedIn) {
          history.push('/login');
          return;
        }

        loadValidVenues();
        loadCcUsers();
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  const computeDisplayUsers = () => {
    if (filterString.trim().length === 0) setDisplayUsers(ccUsers);

    setDisplayUsers(
      ccUsers?.filter(
        (user) =>
          user.emailAddress.toLowerCase().includes(filterString) ||
          user.firstName.toLowerCase().includes(filterString) ||
          user.lastName.toLowerCase().includes(filterString)
      )
    );
  };

  const loadValidVenues = async () => {
    try {
      const response = await ApiClient.getValidVenues();
      if (response.status === 200) {
        setValidVenues(response.data.validVenues);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const selectVenueOption = (
    option: OnChangeValue<SelectedOptionType, true>
  ) => {
    console.log('selectVenueOption');
    console.log(option);
    setRequestInProgress(false);
    setSelectedOptions(option);
  };

  const loadCcUsers = () => {
    ApiClient.getCcUsers()
      .then((response) => {
        setCcUsers(response.data.ccUsers);
        computeDisplayUsers();
      })
      .catch(() => {});
  };

  const createCcUser = () => {
    setErrorText(undefined);

    if (!isFormValid) {
      setErrorText(
        EmailValidator.validate(localCcUser.emailAddress)
          ? 'You must fill out all fields'
          : 'Email address is not valid'
      );
      return;
    }

    const userVenueIds = selectedOptions?.map((option) => option.value);
    console.log(userVenueIds);

    if (userVenueIds == null || userVenueIds?.length === 0) return;

    ApiClient.createCcUser(
      userVenueIds,
      localCcUser.firstName,
      localCcUser.lastName,
      localCcUser.emailAddress
    )
      .then((response) => {
        if (response.status === 400) {
          console.log(response.data);
          setErrorText(response.data);
          return;
        }

        setLocalCcUser(localInitialState);
        setSelectedOptions(null);

        const responseUser = response.data.ccUser;
        if (ccUsers == undefined) {
          setCcUsers([responseUser]);
        } else {
          ccUsers.push(responseUser);
        }

        setCreateUserSuccess(true);
      })
      .catch((error) => {
        setErrorText('A User with this Email already exists');
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    setFilterString('');
    ApiClient.getCcUsers()
      .then((response) => {
        setCcUsers(response.data.ccUsers);
        setDisplayUsers(response.data.ccUsers);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    computeDisplayUsers();
  }, [filterString]);

  useEffect(() => {
    setErrorText(undefined);

    const userVenueIds = selectedOptions?.map((option) => option.value);

    const isValid =
      localCcUser.firstName.length > 0 &&
      localCcUser.lastName.length > 0 &&
      EmailValidator.validate(localCcUser.emailAddress) &&
      userVenueIds != null &&
      userVenueIds?.length > 0;

    setIsFormValid(isValid);

    if (
      localCcUser.firstName.length == 0 &&
      localCcUser.lastName.length == 0 &&
      localCcUser.emailAddress.length == 0
    )
      return;

    if (!isFormValid) {
      if (
        localCcUser.firstName.length > 0 &&
        localCcUser.lastName.length > 0 &&
        EmailValidator.validate(localCcUser.emailAddress) &&
        (userVenueIds == null || userVenueIds?.length === 0)
      ) {
        setErrorText('You must select which Venues this User can Access');
        return;
      }

      setErrorText(
        EmailValidator.validate(localCcUser.emailAddress)
          ? 'You must fill out all fields'
          : 'Email address is not valid'
      );
    }
  }, [localCcUser, selectedOptions]);

  const getContent = () => {
    return (
      <Fragment>
        {isAddNewAccountMode ? (
          <CreateUserModal
            validVenues={validVenues}
            selectedOptions={selectedOptions}
            selectVenueOption={selectVenueOption}
            localCcUser={localCcUser}
            setLocalCcUser={setLocalCcUser}
            isFormValid={isFormValid}
            setIsAddNewAccountMode={setIsAddNewAccountMode}
            errorText={errorText}
            createUserSuccess={createUserSuccess}
            handleSubmit={createCcUser}
          />
        ) : (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '40px 0',
                width: '100%',
              }}
            >
              <input
                style={{ width: 600, padding: 6, marginBottom: 12 }}
                value={filterString}
                placeholder={'Filter by Name or Email'}
                type={'text'}
                onChange={(event) =>
                  setFilterString(event.target.value.toLowerCase())
                }
              />
              <div>
                {(displayUsers ?? ccUsers)
                  ?.sort((u1, u2) => u2.timeCreated - u1.timeCreated)
                  .map((ccUser) => {
                    return (
                      <CcUserListItem
                        handleUpdate={handleUpdate}
                        key={ccUser.userId}
                        ccUser={ccUser}
                        validVenues={validVenues}
                      />
                    );
                  })}
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <PageContainer>
      <BreadCrumbs
        routes={[
          { name: 'Home', route: '/home' },
          {
            name: 'Command Center User Admin',
            route: '/admin/command-centre-users',
          },
        ]}
      />
      <div className="mt-4 w-full max-w-screen-xl px-4 mx-auto lg:px-12">
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
            <div>
              <h5 className="mr-3 font-semibold dark:text-white">
                Command Center Users
              </h5>
              <p className="text-gray-500 dark:text-gray-400">
                Manage all your existing users or add a new one
              </p>
            </div>
            <button
              onClick={() => {
                setIsAddNewAccountMode(true);
                setCreateUserSuccess(false);
                setErrorText(undefined);
                setLocalCcUser(localInitialState);
              }}
              type="button"
              className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5 mr-2 -ml-1"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
              </svg>
              Add new user
            </button>
          </div>
        </div>
      </div>
      <div className="mt-2">
        {loading ? (
          <div className="mt-10">
            <GoldBallLoader />
          </div>
        ) : (
          getContent()
        )}
      </div>
    </PageContainer>
  );
});

const CreateUserModal = ({
  isFormValid,
  setIsAddNewAccountMode,
  localCcUser,
  setLocalCcUser,
  validVenues,
  selectedOptions,
  selectVenueOption,
  createUserSuccess,
  errorText,
  handleSubmit,
}: {
  isFormValid: boolean;
  setIsAddNewAccountMode: (value: boolean) => void;
  localCcUser: any;
  setLocalCcUser: (value: any) => void;
  validVenues: ValidVenue[] | undefined;
  selectedOptions: OnChangeValue<SelectedOptionType, true> | null;
  selectVenueOption: (value: OnChangeValue<SelectedOptionType, true>) => void;
  createUserSuccess: boolean;
  errorText: string | undefined;
  handleSubmit: () => void;
}) => {
  return (
    <div className="p-20 w-full h-full md:h-auto">
      <div className="p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
        <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Enter user details
          </h3>
          <button
            onClick={() => setIsAddNewAccountMode(false)}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="updateProductModal"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="grid gap-4 mb-4 sm:grid-cols-2">
            <div>
              <label
                htmlFor="firstName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={localCcUser.firstName}
                onChange={({ target: { value } }) =>
                  setLocalCcUser({
                    ...localCcUser,
                    firstName: value,
                  })
                }
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={localCcUser.lastName}
                onChange={({ target: { value } }) =>
                  setLocalCcUser({
                    ...localCcUser,
                    lastName: value,
                  })
                }
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={localCcUser.emailAddress}
                onChange={({ target: { value } }) =>
                  setLocalCcUser({
                    ...localCcUser,
                    emailAddress: value,
                  })
                }
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="venues"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Select the Venues the User can Access
            </label>
            <Select
              value={selectedOptions}
              placeholder={'Select Venue/s'}
              isSearchable={false}
              isClearable={false}
              isMulti
              onChange={(newValue: OnChangeValue<SelectedOptionType, true>) =>
                selectVenueOption(newValue)
              }
              options={validVenues
                ?.map((venue) => {
                  return { value: venue.venueId, label: venue.displayName };
                })
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </div>
          <div className="mt-4">
            {errorText && (
              <div
                className="p-2 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                {errorText}
              </div>
            )}
            {createUserSuccess && (
              <div
                className="p-2 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                role="alert"
              >
                <span className="font-medium">Success!</span> User created
                successfully
              </div>
            )}
          </div>
          <div>
            <div className="sm:col-span-2 mt-4">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Note: A link for the user to set their password will be
                automatically emailed to them
              </label>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button
              disabled={!isFormValid}
              type="submit"
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Create user
            </button>
            <button
              onClick={() => setIsAddNewAccountMode(false)}
              type="button"
              className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
