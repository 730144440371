/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react';
import { Fragment, useContext, useEffect, useState } from 'react';
import { reaction, when } from 'mobx';
import { useHistory } from 'react-router';
import { SummaryStore, UserStore } from '../../../index';
import ApiClient from '../../../ApiClient';
import { Columns, secondsSinceToTimestamp } from '../../UiKit';
import { PageContainer, PageHeader, PageLoader } from '../../ui/PageComponents';
import { PELineGraph } from '../../graphs/PELineGraph';
import { Colors } from '../../../Theme';
import { PELineGraphAutoClosed } from '../../graphs/PELineGraphAutoClosed';
import { PEDoubleLineGraph } from '../../graphs/PEDoubleLineGraph';
import { PEDoubleLineGraphWebVsQr } from '../../graphs/PEDoubleLineGraphWebVsQr';
import BreadCrumbs from '../../BreadCrumbs';

export type GeneralAnalytics = {
  averageResponseTimeSecs: number;
  averageResponseTime7DaysSecs: number;
  averageResponseTime30DaysSecs: number;
  unresolvedTicketCount: number;
};

export const AnalyticsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const [generalAnalytics, setGeneralAnalytics] = useState<
    GeneralAnalytics | undefined
  >(undefined);

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined,
      () => {
        history.push('/login');
      }
    );

    // swap venue data
    reaction(
      () => userStore.selectedVenue,
      () => {
        console.log('AnalyticsPage Selected venue changed');

        setGeneralAnalytics(undefined);

        if (userStore.selectedVenue === undefined) return;

        loadGeneralAnalytics();
      }
    );

    loadGeneralAnalytics();
  }, []);

  const loadGeneralAnalytics = () => {
    ApiClient.getGeneralAnalytics()
      .then((response: any) => {
        setGeneralAnalytics({
          averageResponseTimeSecs: response.data.averageResponseTimeSecs,
          averageResponseTime7DaysSecs:
            response.data.averageResponseTime7DaysSecs,
          averageResponseTime30DaysSecs:
            response.data.averageResponseTime30DaysSecs,
          unresolvedTicketCount: response.data.unresolvedTicketCount,
        });
      })
      .catch((error) => {});
  };

  const getPageContent = () => {
    if (generalAnalytics === undefined) {
      return <PageLoader />;
    }

    let ticketColor = Colors.GreenMatte;
    if (generalAnalytics?.unresolvedTicketCount > 0) {
      ticketColor = Colors.RedMatte;
    }

    return (
      <Fragment>
        <PageHeader title={'Analytics'} />

        <div
          css={{
            textAlign: 'center',
            fontSize: 26,
            fontWeight: 'bold',
            marginTop: 12,
            color: ticketColor,
          }}
        >
          Unresolved Ticket Count: {generalAnalytics?.unresolvedTicketCount}
        </div>

        <div
          css={{
            opacity: 0.6,
            height: 1,
            backgroundColor: 'grey',
            width: '80%',
            marginTop: 40,
            marginBottom: 40,
          }}
        />

        <div css={{ fontSize: 20 }}>Average Ticket Response Time</div>

        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            margin: '20px 0',
          }}
        >
          <AnalyticsCircle
            name={'All Days'}
            timeStamp={secondsSinceToTimestamp(
              generalAnalytics?.averageResponseTimeSecs
            )}
          />

          <AnalyticsCircle
            name={'Last 30 Days'}
            timeStamp={secondsSinceToTimestamp(
              generalAnalytics?.averageResponseTime30DaysSecs
            )}
          />

          <AnalyticsCircle
            name={'Last 7 Days'}
            timeStamp={secondsSinceToTimestamp(
              generalAnalytics?.averageResponseTime7DaysSecs
            )}
          />
        </div>

        <div className={'flex-cen-stack'}>
          <div style={{ display: 'flex' }}>
            <PELineGraph
              chartTitle={'Total User Help Cases'}
              itemHexColour={Colors.BlueMatte}
            />

            <PELineGraphAutoClosed
              chartTitle={'Automatically Closed Cases'}
              itemHexColour={Colors.RedMatte}
            />
          </div>

          <div style={{ display: 'flex' }}>
            <PEDoubleLineGraph
              chartTitle={'Member vs Non-Member Cases'}
              firstItemHexColour={Colors.BlueMatte}
              secondItemHexColour={Colors.GreenMatte}
            />

            <PEDoubleLineGraphWebVsQr
              chartTitle={'Kiosk vs QR Code Web Cases'}
              firstItemHexColour={Colors.GreenMatte}
              secondItemHexColour={Colors.BlueMatte}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <PageContainer centerContent>
      <BreadCrumbs
        routes={[
          { name: 'Home', route: '/home' },
          { name: 'Analytics', route: '/analytics' },
        ]}
      />
      {getPageContent()}
    </PageContainer>
  );
});

type AnalyticsCircleProps = {
  name: string;
  timeStamp?: string;
};

const AnalyticsCircle = (props: AnalyticsCircleProps) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px darkgrey solid',
        height: 180,
        width: 180,
        borderRadius: '50%',
        padding: 20,
        backgroundColor: '#F0F1F3',
        marginRight: 30,
      }}
    >
      <div css={{ textAlign: 'center', fontSize: 18 }}>{props.name}</div>

      <div
        css={{
          textAlign: 'center',
          fontSize: 26,
          fontWeight: 'bold',
          marginTop: 12,
        }}
      >
        {props.timeStamp}
      </div>
    </div>
  );
};
