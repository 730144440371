import { UserHelpRequests } from "../../../state/SummaryState";
import { Colors } from "../../../Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFaceSmile,
  faStickyNote,
  faTv,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { secondsSinceToTimestamp } from "../../UiKit";
import { getRequestIcon } from "../../ui/HelperFunctions";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { capitalize } from "lodash";

type Props = {
  case: UserHelpRequests;
};
export function CaseCardContainer(props: Props) {
  return (
    <div className="max-w-xl overflow-auto bg-white border border-gray-200 rounded-xl hover:shadow-xl transition-all dark:bg-gray-800 dark:border-gray-700">
      <SecondsSection userHelpRequest={props.case} />
      <IconTitleSection case={props.case} />
      <ContactInfoSection case={props.case} />
      <ButtonSection case={props.case} />
    </div>
  );
}

function IconTitleSection(props: Props) {
  let icon = null;
  let title = null;
  let createdBy = null;

  switch (props.case.requestSource) {
    case "FACIAL_ID":
      icon = (
        <FontAwesomeIcon icon={faEye} color={Colors.BlueMatte} fontSize={26} />
      );
      title = "FACIAL ID TRIGGERED";
      createdBy = `By the Ok2Play System`;
      break;

    case "THREE_HOUR_GAMBLING":
      icon = (
        <FontAwesomeIcon
          icon={faWarning}
          color={Colors.RedMatte}
          fontSize={26}
        />
      );
      title = `PLAYER TIME ALERT`;
      createdBy = `By the Ok2Play System`;
      break;

    case "INCIDENT_APP":
      icon = (
        <FontAwesomeIcon
          icon={faStickyNote}
          color={Colors.LegoYellow}
          fontSize={26}
        />
      );
      title = "INCIDENT RECORDED";
      createdBy = `By ${
        props.case?.actingStaffMemberName ?? "Unknown"
      } (Incident App)`;
      break;

    case "RESPONDER_APP":
      icon = (
        <FontAwesomeIcon
          icon={faStickyNote}
          color={Colors.LegoYellow}
          fontSize={26}
        />
      );
      title = "INCIDENT RECORDED";
      createdBy = `By ${
        props.case?.actingStaffMemberName ?? "Unknown"
      } (Responder App)`;
      break;

    case "MOBILE":
      icon = (
        <FontAwesomeIcon
          icon={faStickyNote}
          color={Colors.LegoYellow}
          fontSize={26}
        />
      );
      title = "INCIDENT RECORDED";
      createdBy = `By ${
        props.case?.actingStaffMemberName ?? "Unknown"
      } (Mobile)`;
      break;

    case "KIOSK":
      icon = <FontAwesomeIcon icon={faTv} color={"#a11ff0"} fontSize={26} />;
      title = "KIOSK CHECK";
      createdBy = `By the Customer`;
      break;

    case "QR_WEBSITE":
      icon = (
        <FontAwesomeIcon
          icon={faFaceSmile}
          color={Colors.OrangeMatte}
          fontSize={26}
        />
      );
      title = "R U OK CHECK";
      createdBy = `By the Customer`;
      break;
  }

  const uniqueIncidentReasons: any[] =
    props?.case?.incidentReasons?.length > 0
      ? [
          ...(new Set(
            props.case.incidentReasons.map((reason: any) => reason.category)
          ) as any),
        ]
      : [];

  return (
    <div className="p-4">
      <div className="flex gap-4 items-center justify-start">
        <div className="flex-shrink-0 bg-gray-100 rounded-full p-2 w-[55px] h-[55px] flex items-center justify-center">
          {icon}
        </div>
        <div>
          <h5 className="text-xl font-bold font-italic tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
          <p className="text-sm font-normal tracking-tight text-gray-400">
            {createdBy}
          </p>
        </div>
      </div>
      <div>
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-600">
          {props?.case?.incidentReasons?.length > 0 && (
            <span>{props.case.incidentReasons.length} Incident Reasons</span>
          )}
          {props?.case?.customActions?.length > 0 && (
            <>
              {props.case.incidentReasons?.length > 0 && " | "}
              <span>{props.case.customActions.length} Custom Actions</span>
            </>
          )}
          {props?.case?.attachments?.length > 0 && (
            <>
              {(props.case.incidentReasons?.length > 0 ||
                props.case.customActions?.length > 0) &&
                " | "}
              <span>{props.case.attachments.length} Attachments</span>
            </>
          )}
        </p>
        <div className="mt-4 flex gap-2">
          {uniqueIncidentReasons.length > 0 && (
            <div className="flex flex-wrap  items-center gap-2">
              {uniqueIncidentReasons.map((category, index) => (
                <span
                  key={index}
                  className="inline-block min-w-[3rem] max-w-full rounded-3xl text-center bg-gray-100 text-gray-500 text-sm px-2.5 py-1.5 dark:bg-gray-700 dark:text-gray-300"
                >
                  {capitalize(category)}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
      {props.case.requestSource === "FACIAL_ID" && (
        <div
          className={`${
            props.case?.detectedFaceImgUrl ? "h-24" : "h-10"
          } mt-2 w-full rounded-xl bg-[#e5e5ff]`}
        >
          {props.case?.detectedFaceImgUrl ? (
            <div className="flex p-4 justify-between items-center h-full flex-row">
              <p className={`text-[#0819ff]`}>Detected face</p>
              <img
                className="w-20 h-20 object-cover"
                src={props.case?.detectedFaceImgUrl}
              />
            </div>
          ) : (
            <p className="flex justify-center items-center h-full text-gray-500">
              Image not provided
            </p>
          )}
        </div>
      )}

      {props.case.requestSource === "THREE_HOUR_GAMBLING" && (
        <div className={`mt-2 h-16 p-4 w-full rounded-xl bg-[#f9e7eb]`}>
          <p className="font-medium flex justify-center items-center h-full text-[#c5233f]">
            A player has been detected gambling for 3 hours
          </p>
        </div>
      )}

      <div className="my-4 text-right text-gray-500">
        {props.case.machineNumber && (
          <p className="font-normal  dark:text-gray-400">
            Machine # {props.case.machineNumber}
          </p>
        )}
        <p>
          {moment(props.case.requestInitiatedTime).format(
            "MMM Do YYYY, h:mm:ss a"
          )}
        </p>
      </div>
    </div>
  );
}

const SecondsSection = ({
  userHelpRequest,
}: {
  userHelpRequest: UserHelpRequests;
}) => {
  const [secondsSince, setSecondsSince] = useState(() => {
    const currentTime = moment().valueOf();
    return Math.ceil(
      (currentTime - userHelpRequest.requestInitiatedTime) / 1000
    );
  });

  let alertLevel = "OK";
  let borderColor = "border-[#4ede79]";

  if (secondsSince <= 5 * 60 || userHelpRequest.actionStatus === "COMPLETE") {
    // first 5 minutes
    alertLevel = "OK";
    borderColor = "border-[#4ede79]";
  } else if (secondsSince <= 10 * 60) {
    // from 6 to 10 minutes
    alertLevel = "DELAYED";
    borderColor = "border-[#DE9E43]";
  } else {
    // after 10 minutes
    alertLevel = "WARNING";
    borderColor = "border-[#E24A3B]";
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment().valueOf();
      setSecondsSince(
        Math.ceil((currentTime - userHelpRequest.requestInitiatedTime) / 1000)
      );
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [userHelpRequest.requestInitiatedTime]);

  return (
    <div
      className={`flex gap-2 items-center justify-between p-2 border-b-4 h-20 ${borderColor}`}
    >
      <div className="flex flex-col p-2">
        <p className="text-xl font-bold tracking-tight text-gray-900">
          {userHelpRequest.actionStatus === "COMPLETE"
            ? "CASE CLOSED"
            : `Open for ${secondsSinceToTimestamp(secondsSince)}`}
        </p>
        <p className="text-gray-400 font-light">
          {`Case #${userHelpRequest.helpRequestId}`}
        </p>
      </div>

      <p>{getRequestIcon({ ...userHelpRequest, alertLevel })}</p>
    </div>
  );
};

const ContactInfoSection = (props: Props) => {
  if (
    !props.case.requesterFirstName &&
    !props.case.requesterLastName &&
    !props.case.requesterEmailAddress
  ) {
    return (
      <div className="p-4">
        <div className="flex justify-between border-t border-gray-200 dark:border-gray-700 ">
          <div className="mt-2">
            <p className="text-gray-400 dark:text-gray-400">No contact info</p>
            <p className="text-gray-400 italic mt-1 text-xs hover:cursor-pointer hover:opacity-75 transition-all dark:text-gray-400">
              *View case to edit contact info
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="border-t border-gray-200 dark:border-gray-700 ">
        <p className="text-gray-600 dark:text-gray-400 mt-2">Contact Info</p>
        {props.case.requestSource && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Help Request Type:</p>
            <p className="text-gray-600">
              {props.case.requestSource.replace(/_/g, " ").toUpperCase()}
            </p>
          </div>
        )}
        {props.case.requesterMemberNumber && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Member Number:</p>
            <p className="text-gray-600">{props.case.requesterMemberNumber}</p>
          </div>
        )}
        {(props.case.requesterFirstName || props.case.requesterLastName) && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Name:</p>
            <p className="text-gray-600">{`${
              props.case.requesterFirstName || ""
            } ${props.case.requesterLastName || ""}`}</p>
          </div>
        )}
        {props.case.requesterMobileNumber && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Phone Number:</p>
            <p className="text-gray-600">{props.case.requesterMobileNumber}</p>
          </div>
        )}
        {props.case.requesterCaseCount !== 0 && (
          <div className="border-t mt-2 border-gray-200 flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Help Case Count:</p>
            <p className="text-gray-600">{props.case.requesterCaseCount}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const ButtonSection = (props: Props) => {
  const history = useHistory();
  return (
    <div className="p-2">
      <div className="ml-2 pb-2 border-gray-200 flex gap-2 items-center justify-start">
        {/* <a href={`tel:${props.case.requesterMobileNumber}`}>
        <button
          disabled={!props.case.requesterMobileNumber}
          style={{
            backgroundColor: props.case.requesterMobileNumber
              ? 'green'
              : 'gray',
          }}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-full hover:opacity-75 transition-all focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <PhoneArrowUpRightIcon
            className="h-5 w-10 flex-shrink-0 text-white-900 hover:cursor-pointer"
            aria-hidden="true"
          />
          Call
        </button>
      </a> */}
        <button
          onClick={() => {
            history.push(`/case-details/${props.case.helpRequestId}`);
          }}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#eb4c2d] rounded-full hover:opacity-75 transition-all  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          View Case Log
        </button>
      </div>
    </div>
  );
};
