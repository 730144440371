// @ts-nocheck
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react";
import { useContext, useEffect, useRef, useState } from "react";
import { SummaryStore, UserStore } from "../../../index";
import { reaction, runInAction, when } from "mobx";
import { useHistory } from "react-router";
import {
  AlertLevels,
  HelpRequestActionLogItem,
  UserHelpRequests,
} from "../../../state/SummaryState";
import moment from "moment";
import { PageContainer, PageHeader, PageLoader } from "../../ui/PageComponents";
import { CaseActionModal } from "../../modals/CaseActionModal";
import ApiClient from "../../../ApiClient";
import { CaseDetailsCardContainer } from "./case-details-card-container";
import CaseDetailsTabs from "./case-details-tabs";
import { CasePlayerAssessment } from "../../modals/CasePlayerAssessment";
import { CaseNoteModal } from "../../modals/CaseNoteModal";
import BreadCrumbs from "../../BreadCrumbs";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

export type RequestActionTaken =
  | "CREATED"
  | "CLOSE_CASE"
  | "REOPENED"
  | "OPEN_CASE"
  | "ADD_NOTE"
  | "SPOKE_IN_PERSON"
  | "SPOKE_ON_PHONE"
  | "NO_ANSWER"
  | "LEFT_VOICEMAIL"
  | "NUMBER_DOESNT_DIAL"
  | "CALL_BUTTON"
  | "HANDED_TO_THIRD_PARTY";

export const CaseDetailsPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const { helpRequestId } = useParams<{ helpRequestId: string }>();

  const pollingTimer = useRef<NodeJS.Timeout>();

  const [loading, setLoading] = useState(true); // Add loading state
  const [showCaseModal, setShowCaseModal] = useState(false);
  const [openModal, setOpenModal] = useState({
    playerAssessmentModal: false,
    addActionModal: false,
    addNoteModal: false,
  });
  const [requestActionLogs, setRequestActionLogs] = useState<
    HelpRequestActionLogItem[]
  >([]);

  const localState = useLocalObservable<{
    userHelpRequest: UserHelpRequests | undefined;
  }>(() => ({
    userHelpRequest: undefined,
  }));

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined,
      () => {
        history.push("/login");
      }
    );

    reaction(
      () => userStore.selectedVenue,
      () => {
        history.goBack();
      }
    );

    reaction(
      () => userStore.isLoggedIn,
      async (isLoggedIn) => {
        if (!isLoggedIn) {
          history.push("/login");
        }
      },
      {
        fireImmediately: true,
      }
    );

    getHelpRequestDetails();

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  useEffect(() => {
    getHelpRequestDetails();
  }, [helpRequestId]);

  const doAction = (actionTaken: RequestActionTaken, actionNote: string) => {
    ApiClient.doHelpRequestAction(helpRequestId, actionTaken, actionNote)
      .then(() => {
        getHelpRequestDetails();
        setShowCaseModal(false);
      })
      .catch(() => {});
  };

  const getHelpRequestDetails = () => {
    setLoading(true); // Set loading state to true
    ApiClient.getHelpRequestDetails(helpRequestId)
      .then((response) => {
        setRequestActionLogs(response.data.requestActionLogs);

        runInAction(() => {
          localState.userHelpRequest = response.data;

          if (!localState.userHelpRequest) return;

          const currentTime = moment().valueOf();
          const secondsSince = Math.ceil(
            (currentTime - localState.userHelpRequest.requestInitiatedTime) /
              1000
          );

          const minsSince = Math.ceil(secondsSince / 60);
          let alertLevel: AlertLevels;

          if (
            localState.userHelpRequest.actionStatus === "COMPLETE" ||
            minsSince <= 5
          ) {
            alertLevel = "OK";
          } else if (minsSince <= 10) {
            alertLevel = "DELAYED";
          } else {
            alertLevel = "WARNING";
          }

          localState.userHelpRequest.secondsSince = secondsSince;
          localState.userHelpRequest.alertLevel = alertLevel;
        });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false); // Set loading state to false
      });
  };

  const combinedRequests = [
    ...summaryStore.todaysRequests,
    ...summaryStore.previousDayRequests,
    ...summaryStore.last28DaysRequests,
  ];

  const [currentIndex, setCurrentIndex] = useState(
    combinedRequests.findIndex(
      (request) => request.helpRequestId.toString() === helpRequestId
    )
  );

  const prevCase = combinedRequests[currentIndex - 1]?.helpRequestId;
  const nextCase = combinedRequests[currentIndex + 1]?.helpRequestId;

  const handlePrevious = () => {
    if (currentIndex > 0) {
      history.push(`/case-details/${prevCase}`);
    }
  };

  const handleNext = () => {
    if (currentIndex < combinedRequests.length - 1) {
      history.push(`/case-details/${nextCase}`);
    }
  };

  useEffect(() => {
    setCurrentIndex(
      combinedRequests.findIndex(
        (request) => request.helpRequestId.toString() === helpRequestId
      )
    );
  }, [helpRequestId]);

  if (loading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  return (
    <PageContainer>
      <BreadCrumbs
        routes={[
          { name: "Home", route: "/home" },
          { name: "Cases", route: "/cases" },
          { name: "View Case", route: "" },
        ]}
      />
      <PageHeader title={"Case Details"} />
      <CaseActionModal
        showModal={openModal.addActionModal}
        setShowModal={(show) =>
          setOpenModal({ ...openModal, addActionModal: show })
        }
        actionCallback={doAction}
        customActions={summaryStore.customActions}
        incidentReasons={summaryStore.incidentReasons}
        userHelpRequest={localState.userHelpRequest}
      />
      <CaseNoteModal
        showModal={openModal.addNoteModal}
        setShowModal={(show) =>
          setOpenModal({ ...openModal, addNoteModal: show })
        }
        actionCallback={doAction}
        customActions={summaryStore.customActions}
        incidentReasons={summaryStore.incidentReasons}
        userHelpRequest={localState.userHelpRequest}
      />
      <CasePlayerAssessment
        showModal={openModal.playerAssessmentModal}
        setShowModal={(show) =>
          setOpenModal({ ...openModal, playerAssessmentModal: show })
        }
        actionCallback={doAction}
        customActions={summaryStore.customActions}
        incidentReasons={summaryStore.incidentReasons}
        userHelpRequest={localState.userHelpRequest}
      />
      <div className="flex mb-2 justify-between">
        <div className="flex justify-center flex-col items-center">
          <button
            className="bg-[#E24A3B] hover:bg-[#de675c] text-white font-bold w-10 h-10 flex justify-center items-center rounded-full"
            onClick={handlePrevious}
          >
            <ChevronLeftIcon className="h-8 w-8" />
          </button>
          <p className="text-md">
            {prevCase ? `Prev Case #${prevCase}` : `No previous case`}
          </p>
        </div>
        <div className="flex justify-center flex-col items-center">
          <button
            className="bg-[#E24A3B] hover:bg-[#de675c] text-white font-bold w-10 h-10 flex justify-center items-center rounded-full"
            onClick={handleNext}
          >
            <ChevronRightIcon className="h-8 w-8" />
          </button>
          <p className="text-md">Next Case #{nextCase}</p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <CaseDetailsCardContainer
          case={localState.userHelpRequest}
          onClick={setOpenModal}
        />
        <CaseDetailsTabs
          requestActionLogs={requestActionLogs}
          case={localState.userHelpRequest}
        />
      </div>
    </PageContainer>
  );
});
