/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { DataStore, SummaryStore, UserStore } from '../../../index';
import { useHistory } from 'react-router';
import { reaction, runInAction, when } from 'mobx';
import { Card, CardBody, CardColumns, Container } from 'reactstrap';
import { Colors } from '../../../Theme';
import {
  CheckCircleFill,
  ExclamationCircleFill,
  Search,
  X,
  XCircleFill,
} from 'react-bootstrap-icons';
import { CaseListItem } from './CaseListItem';
import { PageContainer, PageHeader } from '../../ui/PageComponents';
import { UserHelpRequests } from '../../../state/SummaryState';
import ApiClient from '../../../ApiClient';
import axios, { CancelTokenSource } from 'axios';
import { GoldBallLoader } from '../../ui/Loading';
import { CaseSearchListItem } from './CaseSearchListItem';
import moment from 'moment/moment';
import { Rows } from '../../UiKit';
import BreadCrumbs from '../../BreadCrumbs';

const cardCss = css({ marginBottom: 24, cursor: 'pointer' });

export const CaseSearchPage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const [isSearching, setIsSearching] = useState(false);

  const currentRequestToken = useRef<CancelTokenSource>();

  useEffect(() => {
    when(
      () => userStore.selectedVenue === undefined,
      () => {
        history.push('/login');
      }
    );

    reaction(
      () => userStore.isLoggedIn,
      (isLoggedIn) => {
        if (!isLoggedIn) {
          history.push('/login');
          return;
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  const onChangeName = (name: string) => {
    console.log(name);
    summaryStore.caseSearchTerm = name;

    if (currentRequestToken.current) {
      currentRequestToken.current?.cancel();
    }
    currentRequestToken.current = axios.CancelToken.source();

    if (summaryStore.searchCaseResults.length > 0) {
      summaryStore.searchCaseResults = [];
    }

    const isNumbers = /^\d+$/.test(name);

    if (!isNumbers && name.length < 3) {
      setIsSearching(false);
      return;
    }

    setIsSearching(true);

    ApiClient.caseSearch(name, currentRequestToken.current)
      .then((response) => {
        setIsSearching(false);
        const cases: UserHelpRequests[] = response.data.searchResults;
        let currentTime = moment().valueOf();

        cases.forEach((request) => {
          request.secondsSince = Math.floor(
            (currentTime - request.requestInitiatedTime) / 1000
          );

          let minsSince = Math.floor(request.secondsSince / 60);
          if (minsSince <= 5) {
            request.alertLevel = 'OK';
          } else if (minsSince <= 10) {
            request.alertLevel = 'DELAYED';
          } else {
            request.alertLevel = 'WARNING';
          }
        });

        summaryStore.searchCaseResults = response.data.searchResults;
      })
      .catch(() => {
        setIsSearching(false);
      });
  };

  const getSearchHelperText = () => {
    if (summaryStore.caseSearchTerm === '') {
      return null;
    }

    if (/^\d+$/.test(summaryStore.caseSearchTerm)) {
      return 'No Results for this Case ID';
    }

    if (
      summaryStore.caseSearchTerm.length > 0 &&
      summaryStore.caseSearchTerm.length < 3
    ) {
      return 'Enter at least 3 letters to search';
    }

    if (summaryStore.caseSearchTerm.length > 0) {
      return 'No Results';
    } else {
      return 'No Results... Enter a First Name, Last Name, or Case ID';
    }
  };

  const clearSearch = () => {
    summaryStore.searchCaseResults = [];
    summaryStore.caseSearchTerm = '';
  };

  return (
    <PageContainer>
      <BreadCrumbs
        routes={[
          { name: 'Home', route: '/home' },
          { name: 'Case Search', route: '/case-search' },
        ]}
      />
      <PageHeader title={'Search Case History'} />

      <Card css={cardCss}>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            cursor: 'default',
          }}
        >
          <h5
            className="card-title"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'normal',
            }}
          >
            <Search
              size={22}
              color={Colors.BlueMatte}
              style={{ marginRight: 8 }}
            />{' '}
            Enter a Members First Name, Last Name or Case ID to search the case
            history
          </h5>

          <Rows>
            <X
              css={{
                color: Colors.RedMatte,
                opacity: summaryStore.caseSearchTerm.length > 0 ? 0.8 : 0,
                marginRight: 4,
                '&:hover': {
                  cursor: 'pointer',
                  color: 'red',
                  alpha: 1.0,
                },
              }}
              size={28}
              onClick={() => clearSearch()}
            />

            <input
              css={{ width: '100%', padding: 6 }}
              value={summaryStore.caseSearchTerm}
              placeholder={'First Name, Last Name or Case ID'}
              type={'text'}
              onChange={({ target: { value } }) => onChangeName(value)}
            />
          </Rows>
        </CardBody>
      </Card>

      {isSearching && (
        <div>
          <GoldBallLoader />
          <div>Searching</div>
        </div>
      )}

      {summaryStore.searchCaseResults.length > 0 ? (
        <Fragment>
          {summaryStore.searchCaseResults.map((request) => {
            return (
              <CaseSearchListItem
                key={'csp' + request.helpRequestId}
                case={request}
              />
            );
          })}
        </Fragment>
      ) : (
        <div css={{ textAlign: 'center', fontSize: 20, marginTop: 30 }}>
          {getSearchHelperText()}
        </div>
      )}
    </PageContainer>
  );
});
