import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { SummaryStore, UserStore } from '../..';
import { PageContainer } from '../ui/PageComponents';
import { CommandCentreVenue } from '../../state/UserState';

const SelectVenuePage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();

  const logout = () => {
    userStore.logout();
  };

  const selectVenue = (venue: CommandCentreVenue) => {
    userStore.selectedVenue = venue;
    userStore.venueSearchTerm = '';
    summaryStore.changeVenue();
  };

  const updateSearchTerm = (venueSearchTerm: string) => {
    userStore.venueSearchTerm = venueSearchTerm;
  };

  const { selectedVenue, userVenues, userVenuesAlphabetical, venueSearchTerm } =
    userStore;

  useEffect(() => {
    if (!userStore.isLoggedIn) {
      history.push('/login');
    }
    if (userStore.selectedVenue) {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    if (selectedVenue) {
      history.push('/home');
    }
  }, [selectedVenue]);

  return (
    <PageContainer>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
          <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Select a venue
            </h2>
          </div>

          {userVenuesAlphabetical && userVenuesAlphabetical.length > 8 && (
            <div className="my-5 w-full flex justify-center">
              <input
                value={venueSearchTerm}
                onChange={(event) =>
                  updateSearchTerm(event.target.value.toLowerCase())
                }
                type="text"
                id="input-group-search"
                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Venue"
              />
            </div>
          )}
          <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
            {userVenuesAlphabetical?.map((venue) => (
              <div
                key={venue.venueId}
                onClick={() => selectVenue(venue)}
                className="p-4 cursor-pointer pl-8 items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700 hover:bg-slate-200 transition-all"
              >
                <img
                  className="w-20 h-20 ml-2 rounded-lg sm:rounded-none sm:rounded-l-lg"
                  src={venue.commCenAppIconUrl}
                  alt="icon"
                />
                <div className="p-5">
                  <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {venue.displayName}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </PageContainer>
  );
});

export default SelectVenuePage;
