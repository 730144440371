import axios, { CancelTokenSource } from "axios";
import UserState from "./state/UserState";
import {
  AlertReceivers,
  StaffResponderAccounts,
  StaffUserAccounts,
  StringSettingType,
} from "./state/SummaryState";
import { RequestActionTaken } from "./components/pages/cases/CaseDetailsPage";
import { OutputData } from "./components/dnd/types";

class ApiClient {
  // userStore = UserStore;
  userState!: UserState;
  apiBaseUrl!: string;

  init(apiBaseUrl: string, userState: UserState) {
    this.userState = userState;
    this.apiBaseUrl = apiBaseUrl;

    // Catch all 401/403 and force a user logout
    axios.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        if (401 === error.response.status || 403 === error.response.status) {
          console.log("FOUND 401 STATUS");
          userState.logout();
        } else {
          return Promise.reject(error);
        }
      }
    );

    axios.defaults.withCredentials = true;
  }

  /** ADMIN STUFF **/
  getVenueSetups() {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/venues`;

    return axios.get(url);
  }

  getDeviceKeys(body: {
    venueId: number;
    appType: "IN_APP_SIGNUP" | "CREATE_CASE_APP";
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/device-key`;

    return axios.post(url, body);
  }

  getCcUsers() {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/cc-users`;

    return axios.get(url);
  }

  addVenueToUser(ccUserId: number, addVenueId: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/cc-users/${ccUserId}/add/${addVenueId}`;

    return axios.post(url);
  }

  deleteVenueFromUser(ccUserId: number, delVenueId: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/cc-users/${ccUserId}/delete/${delVenueId}`;

    return axios.post(url);
  }

  createCcUser(
    userVenueIds: number[],
    firstName: string,
    lastName: string,
    emailAddress: string
  ) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/cc-users/create`;

    return axios.post(url, {
      userVenueIds,
      firstName,
      lastName,
      emailAddress,
    });
  }

  generateNewDeviceKey(body: {
    venueId: number;
    appType: "IN_APP_SIGNUP" | "CREATE_CASE_APP";
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/device-key/create`;

    return axios.post(url, body);
  }

  getValidVenues() {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/valid-venues`;

    return axios.get(url);
  }

  /** OTHER **/
  caseSearch(name: string, cancelToken: CancelTokenSource) {
    let config = {
      cancelToken: cancelToken.token,
    };

    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/case-search/${name}`;

    return axios.post(url, {}, config);
  }

  setVenueCobTime(hours: number, minutes: number, millis?: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/cob-time`;

    return axios.post(url, {
      hours,
      minutes,
      millis,
    });
  }

  getVenueDevices() {
    if (this.userState.getVenueId === undefined)
      return Promise.reject(new Error("Venue ID is undefined"));

    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/venue-devices`;

    return axios.get(url);
  }

  /** Alert Receivers **/
  addNewAlertReceiver(alertReceiver: AlertReceivers) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/add-alert-receiver`;

    return axios.post(url, {
      firstName: alertReceiver.firstName,
      lastName: alertReceiver.lastName,
      phoneNumber: alertReceiver.phoneNumber,
      emailAddress: alertReceiver.emailAddress,
    });
  }

  deleteAlertReceiver(alertReceiverId: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/delete-alert-receiver/${alertReceiverId}`;

    return axios.post(url);
  }

  /** Responder App Accounts **/
  addStaffResponderAccount(staffResponderAccount: StaffResponderAccounts) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/staff/add`;

    return axios.post(url, {
      firstName: staffResponderAccount.firstName,
      lastName: staffResponderAccount.lastName,
      phoneNumber: staffResponderAccount.phoneNumber,
    });
  }

  deleteStaffResponderAccount(venueStaffUserId: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/staff/${venueStaffUserId}/delete`;

    return axios.post(url);
  }

  /** Staff App Accounts **/

  addStaffUserAccount(staffUserAccount: StaffUserAccounts) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/staff/users/add`;

    return axios.post(url, {
      firstName: staffUserAccount.firstName,
      lastName: staffUserAccount.lastName,
      phoneNumber: staffUserAccount.phoneNumber,
    });
  }

  deleteStaffUserAccount(venueStaffUserId: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/staff/users/${venueStaffUserId}/delete`;

    return axios.post(url);
  }

  addSiteLocation(stringContent: string) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/site-location/add`;

    return axios.post(url, {
      stringContent,
    });
  }

  addIncidentReason({
    stringContent,
    category,
  }: {
    stringContent: string;
    category: string;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident-reason/add`;

    return axios.post(url, {
      stringContent,
      category,
    });
  }

  addCustomAction(stringContent: string) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/custom-action/add`;

    return axios.post(url, {
      stringContent,
    });
  }

  deleteIncidentReason(id: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident-reason/${id}/delete`;

    return axios.post(url);
  }

  deleteSiteLocation(id: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/site-location/${id}/delete`;

    return axios.post(url);
  }

  getHomeSummary() {
    if (this.userState.getVenueId === undefined) return Promise.reject();

    const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/home-status`;

    return axios.get(url);
  }

  getHelpRequestDetails(helpRequestId: string) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/${helpRequestId}/detail`;

    return axios.get(url);
  }

  doHelpRequestAction(
    helpRequestId: string,
    actionTaken: RequestActionTaken,
    actionNote: string
  ) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/${helpRequestId}/action`;

    return axios.post(url, {
      actionTaken,
      actionNote,
    });
  }

  /** ANALYTICS **/
  getGeneralAnalytics() {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/analytics/general`;

    return axios.get(url);
  }

  getCaseCountData(dayCount: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/analytics/case-requests/${dayCount}`;

    return axios.get(url);
  }

  getTotalCaseCountData(dayCount: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/analytics/daily-requests/${dayCount}`;

    return axios.get(url);
  }

  getAutoClosedData(dayCount: number) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/analytics/daily-auto-closed/${dayCount}`;

    return axios.get(url);
  }

  /** VENUE THEME / AUTH ENDPOINTS **/
  userLogin(email: string, PIN: string) {
    const url = `${this.apiBaseUrl}/v2/cc/auth/login`;

    return axios.post(url, {
      emailAddress: email,
      password: PIN,
    });
  }

  refreshUserVenues() {
    const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/refresh-user`;

    return axios.post(url);
  }

  setPassword(
    password: string,
    confirmPassword: string,
    setRequestHash: string
  ) {
    const url = `${this.apiBaseUrl}/v2/cc/auth/set-password`;

    return axios.post(url, {
      password,
      confirmPassword,
      setRequestHash,
    });
  }

  userLogout() {
    const url = `${this.apiBaseUrl}/v2/cc/auth/logout`;

    return axios.post(url);
  }

  /** GEO ENDPOINTS **/
  getAllGeofences() {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/geo/fences`;

    return axios.get(url);
  }

  addNewGeofence(name: string, updatedCoordinates: any) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/geo/add-geofence`;

    return axios.post(url, {
      name: name,
      coordinates: updatedCoordinates,
    });
  }

  updateGeofence(locationId: string, name: string, updatedCoordinates: any[]) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/geo/update-geofence/${locationId}`;

    return axios.post(url, {
      name: name,
      coordinates: updatedCoordinates,
    });
  }

  deleteGeofence(locationId: string) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/geo/delete-geofence/${locationId}`;

    return axios.post(url);
  }

  getCaseLogSearch({
    searchType,
    value,
  }: {
    searchType: string;
    value:
      | {
          startTime: string;
          endTime: string;
        }
      | string
      | any;
  }) {
    const utcOffsetMinutes = new Date().getTimezoneOffset();
    const utcOffsetHours = Math.abs(utcOffsetMinutes) / 60;

    if (searchType === "date-range") {
      const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident/log-search/time`;
      return axios.post(url, {
        utcOffsetHours,
        startTime: value.startTime,
        endTime: value.endTime,
      });
    }

    if (searchType === "staff-name") {
      const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident/log-search/staff/${value}`;
      return axios.post(url, {
        utcOffsetHours,
      });
    }
    // member name
    const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident/log-search/person/${value}`;
    return axios.post(url, {
      utcOffsetHours,
    });
  }

  exportCaseLogSearch({
    helpRequestId,
    searchType,
    value,
    exportType,
    columnsDisplay = [],
  }: {
    helpRequestId?: string | number;
    exportType: "excel" | "pdf";
    searchType: string;
    columnsDisplay?:
      | "TimeCreated"
      | "ActingStaffMemberName"
      | "SiteLocation"
      | "Status"
      | "PersonDescription"
      | "Notes ( Other Information )"
      | "KeyNotes"[];
    value:
      | {
          startTime: string;
          endTime: string;
        }
      | string
      | any;
  }) {
    const utcOffsetMinutes = new Date().getTimezoneOffset();
    const utcOffsetHours = Math.abs(utcOffsetMinutes) / 60;

    console.log("utcOffsetHours", utcOffsetHours);

    if (searchType === "singleHelpRequest" && helpRequestId) {
      const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident/${helpRequestId}/download/${exportType}`;
      return axios.post(
        url,
        JSON.stringify({
          columnsDisplay,
          utcOffsetHours,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
    }

    if (searchType === "date-range") {
      const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident/log-search/time?format=${exportType}`;
      return axios.post(
        url,
        JSON.stringify({
          startTime: value.startTime,
          endTime: value.endTime,
          columnsDisplay,
          utcOffsetHours,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
    }

    if (searchType === "staff-name") {
      const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident/log-search/staff/${value}?format=${exportType}`;
      return axios.post(
        url,
        JSON.stringify({
          utcOffsetHours,
          columnsDisplay,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
    }
    // member name
    const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident/log-search/person/${value}?format=${exportType}`;
    return axios.post(
      url,
      JSON.stringify({
        utcOffsetHours,
        columnsDisplay,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );
  }

  getSmsData() {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/sms`;
    return axios.get(url);
  }

  sendPasswordResetEmail(email: string) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/cc-users/reset-password`;

    return axios.post(url, {
      emailAddress: email,
    });
  }

  saveSmsData(smsData: { SmsFieldTypes: string; SmsTemplateText: string }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/sms/update-sms-field`;

    return axios.post(url, smsData);
  }

  renameIncidentReasonCategory(data: {
    OldCategory: string;
    NewCategory: string;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident-reason/rename-category`;
    return axios.post(url, data);
  }

  customActionSorting(data: {
    data: {
      Id: number;
      SortOrder: number;
    }[];
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/custom-action/sort`;
    return axios.post(url, {
      OtherVenueSettings: data,
    });
  }

  venueLocationSorting(data: {
    data: {
      Id: number;
      SortOrder: number;
    }[];
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/site-location/sort`;
    return axios.post(url, { OtherVenueSettings: data });
  }

  changeIncidentReasonCategory(data: {
    venueStringSettingsId: number;
    category: string;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident-reason/${data.venueStringSettingsId}/update`;
    return axios.post(url, {
      Category: data.category,
    });
  }

  updateIncidentSortings(data: OutputData[]) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident-reason/sort`;
    return axios.post(url, {
      incidents: data,
    });
  }

  updateSortOrderIncidentReasonCategory(
    data: {
      CategoryId: number;
      CategoryName: string;
      SortOrder: number;
    }[]
  ) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incident-reason/category-sort`;
    return axios.post(url, data);
  }

  getIncidentsOverview() {
    const url = `${this.apiBaseUrl}/v2/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/incidents/overview`;
    return axios.get(url);
  }

  // user-requests/{helpRequestId}/contact-information/update

  updateContactInformation({
    helpRequestId,
    data,
  }: {
    helpRequestId: number;
    data:
      | {
          RequesterFirstName: string | undefined;
          RequesterLastName: string | undefined;
          RequesterMobileNumber: string | undefined;
          RequesterEmailAddress: string | undefined;
        }
      | any;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/${helpRequestId}/contact-information/update`;

    return axios.post(url, data);
  }
  async uploadAttachmentInCaseDetails({
    file,
    helpRequestId,
  }: {
    file: File;
    helpRequestId: number;
  }) {
    const url1 = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/presigned-upload-url`;
    const url2 = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/${helpRequestId}/attachment`;

    try {
      const res1 = await axios.post(url1, {
        ImageFileName: file.name,
      });

      await axios.put(res1.data.imageUploadUrl, file, {
        withCredentials: false,
      });
      await axios.post(url2, {
        s3Key: res1.data.s3Key,
      });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  addActionToIncident({
    requestId,
    actionNote,
    newIncidentReasons,
    removedIncidentReasonIds,
    customActions,
    ActionTaken,
  }: {
    requestId?: number;
    actionNote?: string;
    newIncidentReasons?: number[];
    removedIncidentReasonIds?: number[];
    customActions?: {
      VenueStringSettingId: string;
      action: string;
    }[];
    ActionTaken?: string;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/${requestId}/incident/action`;

    return axios.post(url, {
      actionNote,
      newIncidentReasons,
      removedIncidentReasonIds,
      customActions,
      ActionTaken,
    });
  }

  addActionNote({
    KeyNotes,
    helpRequestId,
  }: {
    KeyNotes: string;
    helpRequestId: number;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/${helpRequestId}/note`;

    return axios.post(url, {
      KeyNotes,
    });
  }

  deleteCCUser({ ccUserId }: { ccUserId: number }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/cc-users/${ccUserId}/delete`;
    return axios.post(url);
  }

  editCcUser({
    ccUserId,
    firstName,
    lastName,
  }: {
    ccUserId: number;
    firstName: string;
    lastName: string;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/admin/cc-users/${ccUserId}/update`;
    return axios.post(url, {
      firstName,
      lastName,
    });
  }

  async getSingleUploadPresignedS3Url({ file }: { file: File }) {
    const url1 = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${this.userState.getVenueId}/user-requests/presigned-upload-url`;

    const res1 = await axios.post(url1, {
      ImageFileName: file.name,
    });

    await axios.put(res1.data.imageUploadUrl, file, {
      withCredentials: false,
    });

    return res1.data.s3Key;
  }

  getVenueSetupStatusData(venueId: number) {
    if (!venueId && venueId !== 0) {
      return;
    }

    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${venueId}/setup-status`;

    return axios.get(url);
  }

  updateVenue({
    venueId,
    DisplayName,
    NameKeyStr,
    StateLocation,
    VenueLogoS3Key,
  }: // UserCheckWebsiteText,
  {
    venueId: number;
    DisplayName?: string;
    NameKeyStr?: string;
    StateLocation?: string;
    VenueLogoS3Key?: string;
    // UserCheckWebsiteText?: string;
  }) {
    const url = `${this.apiBaseUrl}/v1/cc/${this.userState.getUserId}/venue/${venueId}/update-venue`;

    // Create an object with the provided fields
    const fields = {
      DisplayName,
      NameKeyStr,
      StateLocation,
      VenueLogoS3Key,
      // UserCheckWebsiteText,
    };

    // Filter out fields that are nullish (undefined, null, or empty string)
    const filteredFields = Object.fromEntries(
      Object.entries(fields).filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
    );

    // If all fields are empty, skip the API call
    if (Object.keys(filteredFields).length === 0) {
      console.log("No fields to update; skipping API call.");
      return Promise.resolve(); // Resolve without making the call
    }

    // Make the API call with the filtered fields
    return axios.post(url, filteredFields);
  }
}

export default new ApiClient();
