import { useContext, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { SummaryStore, UserStore } from "../../../index";
import { useHistory } from "react-router";
import { reaction } from "mobx";
import {
  PageContainer,
  PageLoader,
  PageSubHeader,
} from "../../ui/PageComponents";
import moment from "moment/moment";
import { CaseCardContainer } from "./case-card-container";
import React from "react";
import ApiClient from "../../../ApiClient";
import BreadCrumbs from "../../BreadCrumbs";
import { isEmpty } from "lodash";

export const CasePage = observer(() => {
  const userStore = useContext(UserStore);
  const summaryStore = useContext(SummaryStore);
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const pollingTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    reaction(
      () => [userStore.isLoggedIn, userStore.selectedVenue],
      (values) => {
        const isLoggedIn = values[0];
        const selectedVenue = values[1];

        if (!isLoggedIn || !selectedVenue) {
          history.push("/login");
        }
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      if (pollingTimer.current) clearInterval(pollingTimer.current);
    };
  }, []);

  useEffect(() => {
    if (
      isEmpty(summaryStore.todaysRequests) &&
      isEmpty(summaryStore.previousDayRequests) &&
      isEmpty(summaryStore.last28DaysRequests)
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    summaryStore.todaysRequests,
    summaryStore.previousDayRequests,
    summaryStore.last28DaysRequests,
  ]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <PageContainer
      breadcrumbs={[
        { name: "Home", route: "/home" },
        { name: "Cases", route: "/cases" },
      ]}
    >
      <BreadCrumbs
        routes={[
          { name: "Home", route: "/home" },
          { name: "Cases", route: "/cases" },
        ]}
      />
      <div style={{ fontSize: 26, textAlign: "center", marginBottom: 16 }}>
        Cases since {moment(summaryStore.caseSearchFilterTime).format("h:mm a")}{" "}
        today: {summaryStore.todaysRequests.length}
      </div>

      {summaryStore.todaysRequests.length === 0 ? (
        <PageSubHeader title={"There are no cases today"} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {summaryStore.todaysRequests.map((request, index) => (
            <div key={index} className="m-2">
              <CaseCardContainer case={request as any} />
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          fontSize: 26,
          textAlign: "center",
          marginTop: 30,
          marginBottom: 16,
        }}
      >
        Automatically Closed Cases: {summaryStore.previousDayRequests.length}{" "}
        cases
      </div>

      {summaryStore.previousDayRequests.length === 0 ? (
        <PageSubHeader title={"There are no automatically closed cases"} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {summaryStore.previousDayRequests.map((request, index) => (
            <div key={index} className="m-2">
              <CaseCardContainer case={request as any} />
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          fontSize: 26,
          textAlign: "center",
          marginTop: 30,
          marginBottom: 16,
        }}
      >
        Last 14 Days: {summaryStore.last28DaysRequests.length}
      </div>

      {summaryStore.last28DaysRequests.length === 0 ? (
        <PageSubHeader title={"There are no cases from the last 14 Days"} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {summaryStore.last28DaysRequests.map((request, index) => (
            <div key={index} className="m-2">
              <CaseCardContainer case={request as any} />
            </div>
          ))}
        </div>
      )}
    </PageContainer>
  );
});
