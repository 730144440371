import { Route, Switch } from "react-router";
import { Layout } from "./components/layout/Layout";
import { HomePage } from "./components/pages/HomePage";
import { AlertReceiverSetupPage } from "./components/pages/venue-setup/AlertReceiverSetupPage";
import { StaffResponderAccountsPage } from "./components/pages/venue-setup/StaffResponderAccountsPage";
import { StaffUserAccountsPage } from "./components/pages/venue-setup/StaffUserAccountsPage";
import { IncidentReasonsPage } from "./components/pages/venue-setup/IncidentReasonsPage";
import { SiteLocationsPage } from "./components/pages/venue-setup/SiteLocationsPage";
import { CaseDetailsPage } from "./components/pages/cases/CaseDetailsPage";
import { SettingsPage } from "./components/pages/venue-setup/SettingsPage";
import { CasePage } from "./components/pages/cases/CasePage";
import { CaseSearchPage } from "./components/pages/cases/CaseSearchPage";
import { SearchCaseDetailsPage } from "./components/pages/cases/SearchCaseDetailsPage";
import { VenuesSetupAdminPage } from "./components/pages/admin/VenuesSetupAdminPage";
import { CcAccountsAdminPage } from "./components/pages/admin/CcAccountsAdminPage";
import { IncidentAppVenueDeviceKeysAdminPage } from "./components/pages/admin/IncidentAppVenueDeviceKeysAdminPage";
import { LoginSetupPage } from "./components/pages/LoginSetupPage";
import { AnalyticsPage } from "./components/pages/analyticsreports/AnalyticsPage";
import { VenueDevicesPage } from "./components/pages/venue-setup/VenueDevicesPage";
import { GeoSafetyAdminPage } from "./components/pages/geo-safety/GeoSafetyAdminPage";
import { GeoCreatorAdminPage } from "./components/pages/geo-safety/GeoCreatorPage";
import { SmsPage } from "./components/pages/sms/SmsPage";
// import { ReportsPage } from './components/pages/analyticsreports/ReportsPage';
import { CaseLogSearchPage } from "./components/pages/analyticsreports/CaseLogSearchPage";
import { CustomActionsPage } from "./components/pages/venue-setup/CustomActionsPage";
import { ResponderAppVenueDeviceKeysAdminPage } from "./components/pages/admin/ResponderAppVenueDeviceKeysAdminPage";
import LoginPage from "./components/pages/LoginPage";
import SelectVenuePage from "./components/pages/select-venue";
import VenueSetupStatusPage from "./components/pages/venue-setup-status-page";

export const App = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/home" component={HomePage} />
        <Route
          path="/case-details/:helpRequestId"
          component={CaseDetailsPage}
        />
        <Route
          path="/search-case-details/:helpRequestId"
          component={SearchCaseDetailsPage}
        />
        <Route path="/login" component={LoginPage} />
        <Route path="/set-password/:setupHash" component={LoginSetupPage} />
        <Route path="/analytics" component={AnalyticsPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/cases" component={CasePage} />
        <Route path="/venue-devices" component={VenueDevicesPage} />
        <Route path="/alert-receivers" component={AlertReceiverSetupPage} />
        <Route
          path="/staff-responder-accounts"
          component={StaffResponderAccountsPage}
        />
        <Route path="/staff-user-accounts" component={StaffUserAccountsPage} />
        <Route path="/incident-reasons" component={IncidentReasonsPage} />
        <Route path="/custom-actions" component={CustomActionsPage} />
        <Route path="/site-locations" component={SiteLocationsPage} />
        <Route path="/case-search" component={CaseSearchPage} />
        <Route path="/admin/venues" component={VenuesSetupAdminPage} />
        <Route
          path="/admin/command-centre-users"
          component={CcAccountsAdminPage}
        />
        <Route path="/admin/geo-safety" component={GeoSafetyAdminPage} />
        <Route path="/admin/geo-creator" component={GeoCreatorAdminPage} />
        <Route path="/sms-configuration" component={SmsPage} />
        {/* <Route path="/reporting" component={ReportsPage} /> */}
        <Route path="/gaming-incident-register" component={CaseLogSearchPage} />
        <Route
          path="/admin/venue-setup-status"
          component={VenueSetupStatusPage}
        />
        <Route
          path="/admin/incident-app-device-keys"
          component={IncidentAppVenueDeviceKeysAdminPage}
        />
        <Route
          path="/admin/responder-app-device-keys"
          component={ResponderAppVenueDeviceKeysAdminPage}
        />{" "}
        <Route path="/select-venue" component={SelectVenuePage} />
        <Route path="/" component={HomePage} />
      </Switch>
    </Layout>
  );
};
