import { AlertLevels, UserHelpRequests } from "../../../state/SummaryState";
import { Colors } from "../../../Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFaceSmile,
  faFileExcel,
  faFilePdf,
  faStickyNote,
  faTv,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { secondsSinceToTimestamp } from "../../UiKit";
import { getRequestIcon } from "../../ui/HelperFunctions";
import { Fragment, useEffect, useState } from "react";
import ApiClient from "../../../ApiClient";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as EmailValidator from "email-validator";
import {
  handleColumns,
  initialColumnsState,
} from "../analyticsreports/CaseLogSearchPage";

type SourceProps = {
  case: UserHelpRequests;
  onClick?: React.Dispatch<
    React.SetStateAction<{
      playerAssessmentModal: boolean;
      addActionModal: boolean;
      addNoteModal: boolean;
    }>
  >;
};
type CaseCardContainerProps = {
  case: UserHelpRequests;
  onClick?: React.Dispatch<
    React.SetStateAction<{
      playerAssessmentModal: boolean;
      addActionModal: boolean;
      addNoteModal: boolean;
    }>
  >;
};
export function CaseDetailsCardContainer(props: CaseCardContainerProps) {
  return (
    <div className="max-w-xl overflow-auto bg-white border border-gray-200 rounded-xl hover:shadow-xl transition-all dark:bg-gray-800 dark:border-gray-700">
      <SecondsSection case={props.case} />
      <IconTitleSection case={props.case} />
      <ContactInfoSection case={props.case} />
      <AssessmentInfoSection case={props.case} />
      <ButtonSection case={props.case} onClick={props.onClick} />
    </div>
  );
}

function IconTitleSection(props: SourceProps) {
  let icon = null;
  let title = null;
  let createdBy = null;

  switch (props.case.requestSource) {
    case "FACIAL_ID":
      icon = (
        <FontAwesomeIcon icon={faEye} color={Colors.BlueMatte} fontSize={26} />
      );
      title = "FACIAL ID TRIGGERED";
      createdBy = `By the Ok2Play System`;
      break;

    case "THREE_HOUR_GAMBLING":
      icon = (
        <FontAwesomeIcon
          icon={faWarning}
          color={Colors.RedMatte}
          fontSize={26}
        />
      );
      title = `PLAYER TIME ALERT`;
      createdBy = `By the Ok2Play System`;
      break;

    case "INCIDENT_APP":
      icon = (
        <FontAwesomeIcon
          icon={faStickyNote}
          color={Colors.LegoYellow}
          fontSize={26}
        />
      );
      title = "INCIDENT RECORDED";
      createdBy = `By ${
        props.case?.actingStaffMemberName ?? "Unknown"
      } (Incident App)`;
      break;

    case "RESPONDER_APP":
      icon = (
        <FontAwesomeIcon
          icon={faStickyNote}
          color={Colors.LegoYellow}
          fontSize={26}
        />
      );
      title = "INCIDENT RECORDED";
      createdBy = `By ${
        props.case?.actingStaffMemberName ?? "Unknown"
      } (Responder App)`;
      break;

    case "MOBILE":
      icon = (
        <FontAwesomeIcon
          icon={faStickyNote}
          color={Colors.LegoYellow}
          fontSize={26}
        />
      );
      title = "INCIDENT RECORDED";
      createdBy = `By ${
        props.case?.actingStaffMemberName ?? "Unknown"
      } (Mobile)`;
      break;

    case "KIOSK":
      icon = <FontAwesomeIcon icon={faTv} color={"#a11ff0"} fontSize={26} />;
      title = "KIOSK CHECK";
      createdBy = `By the Customer`;
      break;

    case "QR_WEBSITE":
      icon = (
        <FontAwesomeIcon
          icon={faFaceSmile}
          color={Colors.OrangeMatte}
          fontSize={26}
        />
      );
      title = "R U OK";
      createdBy = `By the Customer`;
      break;
  }

  return (
    <div className="p-4">
      <div className="flex gap-4 items-center justify-start">
        {icon && (
          <div className="flex-shrink-0 bg-gray-100 rounded-full p-2 w-[55px] h-[55px] flex items-center justify-center">
            {icon}
          </div>
        )}
        <div className="flex flex-col">
          <h5 className="text-2xl font-bold font-italic tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
          <p className="text-sm font-normal tracking-tight text-gray-400">
            {createdBy}
          </p>
          <p className="text-sm font-normal tracking-tight text-gray-400">
            {moment(props.case.requestInitiatedTime).format(
              "MMM Do YYYY, h:mm:ss a"
            )}
          </p>
        </div>
      </div>

      <div className="mt-4">
        {props.case.requestSource === "FACIAL_ID" && (
          <div
            className={`${
              props.case?.detectedFaceImgUrl ? "h-24" : "h-10"
            } mt-2 w-full rounded-xl bg-[#e5e5ff]`}
          >
            {props.case?.detectedFaceImgUrl ? (
              <div className="flex p-4 justify-between items-center h-full flex-row">
                <p className={`text-[#0819ff]`}>Detected face</p>
                <img
                  className="w-20 h-20 object-cover"
                  src={props.case?.detectedFaceImgUrl}
                />
              </div>
            ) : (
              <p className="flex justify-center items-center h-full text-gray-500">
                Image not provided
              </p>
            )}
          </div>
        )}

        {props.case.requestSource === "THREE_HOUR_GAMBLING" && (
          <div className={`mt-2 h-16 p-4 w-full rounded-xl bg-[#f9e7eb]`}>
            <p className="font-medium flex justify-center items-center h-full text-[#c5233f]">
              A player has been detected gambling for 3 hours
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

const SecondsSection = (props: SourceProps) => {
  const [secondsSince, setSecondsSince] = useState(
    () => props.case.secondsSince
  );

  let alertLevel = "OK";
  let borderColor = "border-[#4ede79]";

  if (secondsSince <= 5 * 60 || props.case.actionStatus === "COMPLETE") {
    // first 5 minutes
    alertLevel = "OK";
    borderColor = "border-[#4ede79]";
  } else if (secondsSince <= 10 * 60) {
    // from 6 to 10 minutes
    alertLevel = "DELAYED";
    borderColor = "border-[#DE9E43]";
  } else {
    // after 10 minutes
    alertLevel = "WARNING";
    borderColor = "border-[#E24A3B]";
  }

  useEffect(() => {
    if (props.case.actionStatus !== "COMPLETE") {
      const intervalId = setInterval(() => {
        const currentTime = moment().valueOf();
        setSecondsSince(
          Math.ceil((currentTime - props.case.requestInitiatedTime) / 1000)
        );
      }, 1000);

      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [props.case.actionStatus, props.case.requestInitiatedTime]);

  return (
    <div
      className={`flex gap-2 items-center justify-between p-2 border-b-4 h-20 ${borderColor}`}
    >
      <div className="flex flex-col p-2">
        {props.case.actionStatus === "COMPLETE" ? (
          <p className="text-xl font-bold tracking-tight text-gray-900">
            CASE CLOSED
          </p>
        ) : (
          <p className="text-xl font-bold tracking-tight text-gray-900">
            Open for {secondsSinceToTimestamp(secondsSince)}
          </p>
        )}
        <p className="text-gray-400 font-light">
          {`Case #${props.case.helpRequestId}`}
        </p>
      </div>
      <p className="mr-2">
        {getRequestIcon({
          ...props.case,
          alertLevel: alertLevel as AlertLevels,
        })}
      </p>
    </div>
  );
};

const ContactInfoSection = (props: SourceProps) => {
  const [isEditingContact, setIsEditingContact] = useState(false);
  const [data, setData] = useState({
    RequesterFirstName: props.case.requesterFirstName,
    RequesterLastName: props.case.requesterLastName,
    RequesterMobileNumber: props.case.requesterMobileNumber,
    RequesterEmailAddress: props.case.requesterEmailAddress,
  });

  const [errors, setErrors] = useState("");

  const handleSubmit = () => {
    setErrors("");
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value != null && value !== ""
      )
    );

    console.log(filteredData);

    if (filteredData.RequesterMobileNumber) {
      try {
        if (!isValidPhoneNumber(filteredData.RequesterMobileNumber, "AU")) {
          setErrors("Invalid phone number");
          return;
        }
      } catch (err) {
        console.log(err);
        setErrors("Invalid phone number");
        return;
      }
    }

    if (
      filteredData.RequesterEmailAddress &&
      !EmailValidator.validate(filteredData.RequesterEmailAddress)
    ) {
      setErrors("Invalid email address");
      return;
    }

    ApiClient.updateContactInformation({
      helpRequestId: props.case.helpRequestId,
      data: filteredData,
    })
      .then((response) => {
        console.log(response);
        setIsEditingContact(false);
      })
      .catch((error) => {
        setErrors("Something went wrong. Try again later.");
        console.log(error);
      });
  };

  const contactInfo = () => {
    return (
      <Fragment>
        {props.case.requestSource && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Help Request Type:</p>
            <p className="text-gray-600">
              {props.case.requestSource.replace(/_/g, " ").toUpperCase()}
            </p>
          </div>
        )}
        {props.case.requesterMemberNumber && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Member Number:</p>
            <p className="text-gray-600">{props.case.requesterMemberNumber}</p>
          </div>
        )}
        {(props.case.requesterFirstName || props.case.requesterLastName) && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Name:</p>
            <p className="text-gray-600">{`${
              props.case.requesterFirstName || ""
            } ${props.case.requesterLastName || ""}`}</p>
          </div>
        )}
        {props.case.personDescription && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Person Description:</p>
            <p className="text-gray-600">{`${props.case.personDescription}`}</p>
          </div>
        )}
        {props.case.requesterMobileNumber && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Phone Number:</p>
            <p className="text-gray-600">{props.case.requesterMobileNumber}</p>
          </div>
        )}
        {props.case.requesterEmailAddress && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Email Address:</p>
            <p className="text-gray-600">{props.case.requesterEmailAddress}</p>
          </div>
        )}
        {props.case.machineNumber && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Machine Number:</p>
            <p className="text-gray-600">{props.case.machineNumber}</p>
          </div>
        )}
        {props.case.siteLocation && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Site Location:</p>
            <p className="text-gray-600">{props.case.siteLocation}</p>
          </div>
        )}
        {props.case.requesterCaseCount !== 0 && (
          <div className="flex gap-2 text-gray-400 items-center justify-between">
            <p className="mt-2">Help Case Count:</p>
            <p className="text-gray-600">{props.case.requesterCaseCount}</p>
          </div>
        )}
      </Fragment>
    );
  };

  const editContactInfo = () => {
    return (
      <Fragment>
        <div className="mt-2 flex gap-2 items-center justify-between">
          <p className="mt-2">First Name:</p>
          <input
            className="mt-2 border border-gray-200 rounded-md p-2 w-1/2"
            value={data.RequesterFirstName}
            onChange={(e) =>
              setData({ ...data, RequesterFirstName: e.target.value })
            }
          />
        </div>
        <div className="mt-2 flex gap-2 items-center justify-between">
          <p className="mt-2">Last Name:</p>
          <input
            className="border border-gray-200 rounded-md p-2 w-1/2"
            value={data.RequesterLastName}
            onChange={(e) =>
              setData({ ...data, RequesterLastName: e.target.value })
            }
          />
        </div>
        <div className="mt-2 flex gap-2 items-center justify-between">
          <p className="mt-2">Mobile Number:</p>
          <input
            className="border border-gray-200 rounded-md p-2 w-1/2"
            value={data.RequesterMobileNumber}
            onChange={(e) =>
              setData({ ...data, RequesterMobileNumber: e.target.value })
            }
          />
        </div>{" "}
        <div className="mt-2 flex gap-2 items-center justify-between">
          <p className="mt-2">Email Address:</p>
          <input
            className="border border-gray-200 rounded-md p-2 w-1/2"
            value={data.RequesterEmailAddress}
            onChange={(e) =>
              setData({ ...data, RequesterEmailAddress: e.target.value })
            }
          />
        </div>
        <div>
          <p className="text-red-500">{errors}</p>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            onClick={() => setIsEditingContact(false)}
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </Fragment>
    );
  };

  useEffect(() => {
    setErrors("");
  }, [data]);

  return (
    <div className="p-4">
      <div>
        {" "}
        <div className="flex justify-between pb-2">
          <p className="font-bold dark:text-gray-400 mt-2">Contact Info</p>

          <p
            onClick={() => setIsEditingContact(!isEditingContact)}
            className="font-normal text-sm italic text-[#eb4c2d] hover:cursor-pointer hover:opacity-75 transition-all dark:text-gray-400 mt-2"
          >
            add or edit contact info
          </p>
        </div>
        {isEditingContact ? editContactInfo() : contactInfo()}
      </div>
    </div>
  );
};

const AssessmentInfoSection = (props: SourceProps) => {
  const incidentReasons = props.case.incidentReasons.sort((a, b) =>
    a.incidentDescription.localeCompare(b.incidentDescription)
  );
  const customActions = props.case.customActions.sort((a, b) =>
    a.incidentDescription.localeCompare(b.incidentDescription)
  );

  return (
    <div className="p-4">
      <div className="mt-4 border-t border-gray-200"></div>
      <CardsScrollContent title={"Player Assessment"} data={incidentReasons} />
      <CardsScrollContent
        title={"Staff Member Interactions"}
        data={customActions}
      />
    </div>
  );
};
const ButtonSection = (props: SourceProps) => {
  const setOpenModal = props.onClick;

  const handleExportHelpRequest = ({ exportType }: { exportType: any }) => {
    const columnsDisplay = handleColumns(initialColumnsState);
    ApiClient.exportCaseLogSearch({
      helpRequestId: props.case.helpRequestId,
      columnsDisplay,
      searchType: "singleHelpRequest",
      exportType,
      value: null,
    })
      .then((response: any) => {
        if (response.status !== 200) {
          return;
        }

        if (exportType === "pdf") {
          // Convert response data to a Blob
          const blob = new Blob([response.data], {
            type: "application/pdf", // Assuming response.data contains the PDF data
          });
          const fileName = `Incident_Logs_${moment().format(
            "YYYY-MM-DD_HH-mm-ss"
          )}.pdf`;

          const link = document.createElement("a");
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browsers need the anchor to be in the doc
          document.body.append(link);
          link.click();
        }

        if (exportType === "excel") {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Correct MIME type for Excel
          });
          const fileName = `Incident_Logs_${moment().format(
            "YYYY-MM-DD_HH-mm-ss"
          )}.xlsx`;

          const link = document.createElement("a");
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browsers need the anchor to be in the doc
          document.body.append(link);
          link.click();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <div className="p-2 flex">
        <div className="ml-2 pb-2 border-gray-200 flex gap-2 items-center justify-start">
          <button
            onClick={() => {
              setOpenModal &&
                setOpenModal({
                  playerAssessmentModal: true,
                  addActionModal: false,
                  addNoteModal: false,
                });
            }}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-[#eb4c2d] border-2 rounded-full border-[#eb4c2d] hover:opacity-75 transition-all focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Player Assessment
          </button>
        </div>
        <div className="ml-2 pb-2 border-gray-200 flex gap-2 items-center justify-start">
          <button
            onClick={() => {
              setOpenModal &&
                setOpenModal({
                  playerAssessmentModal: false,
                  addActionModal: true,
                  addNoteModal: false,
                });
            }}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-[#eb4c2d] border-2 rounded-full border-[#eb4c2d] hover:opacity-75 transition-all  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Add Action
          </button>
        </div>
        <div className="ml-2 pb-2 border-gray-200 flex gap-2 items-center justify-start">
          <button
            onClick={() =>
              setOpenModal &&
              setOpenModal({
                playerAssessmentModal: false,
                addActionModal: false,
                addNoteModal: true,
              })
            }
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-[#eb4c2d] border-2 rounded-full border-[#eb4c2d] hover:opacity-75 transition-all  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Add Note
          </button>
        </div>
      </div>
      <div className="mt-4 mb-4 flex w-full gap-4 items-center justify-center">
        <div
          onClick={() => {
            handleExportHelpRequest({
              exportType: "excel",
            });
          }}
          className="flex items-center bg-gradient-to-r from-green-700 to-green-800 hover:from-green-800 hover:to-green-900 p-3 rounded-lg shadow-lg transition duration-150 ease-in-out cursor-pointer max-w-xs"
        >
          <span className="text-gray-200 font-medium truncate">
            Download as Excel
          </span>
          <FontAwesomeIcon
            icon={faFileExcel}
            fontSize="20px"
            className="text-gray-200 ml-2"
          />
        </div>

        <div
          onClick={() => {
            handleExportHelpRequest({
              exportType: "pdf",
            });
          }}
          className="flex items-center bg-gradient-to-r from-red-700 to-red-800 hover:from-red-800 hover:to-red-900 p-3 rounded-lg shadow-lg transition duration-150 ease-in-out cursor-pointer max-w-xs"
        >
          <span className="text-gray-200 font-medium truncate">
            Download as PDF
          </span>
          <FontAwesomeIcon
            icon={faFilePdf}
            fontSize="20px"
            className="text-gray-200 ml-2"
          />
        </div>
      </div>
    </div>
  );
};

const CardsScrollContent = (props: { title: string; data: any[] }) => {
  return (
    <div className="mt-2">
      <div className="mt-2 flex gap-2 items-center justify-between">
        <p className="mt-2 text-black font-bold">{`${props?.title} (${props?.data?.length})`}</p>
      </div>

      <div className="overflow-auto flex gap-4 mt-2">
        {props?.data?.length > 0 &&
          props?.data
            ?.sort((a, b) =>
              moment(a?.timeCreated).isBefore(moment(b?.timeCreated)) ? 1 : -1
            )
            ?.map((item, i) => (
              <div
                key={`i_${i}`}
                className="my-2 bg-gray-200 whitespace-nowrap p-4 rounded-xl"
              >
                <div className="flex flex-col">
                  <p>{item?.incidentDescription}</p>
                  <p className="text-gray-400">
                    {props?.title !== "Staff Member Interactions" &&
                      item?.category}
                  </p>
                  <p className="text-gray-400">
                    {moment(item?.timeCreated).format("MMM Do YYYY, h:mm:ss a")}
                  </p>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};
