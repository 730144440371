import { PhotoIcon } from '@heroicons/react/24/solid';
import { ErrorAlert } from './alerts';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

type Props = {
  id: string;
  label: string;
  description: string;
  blobUrl: string | null;
  errorMessage: string;
  handleFileChange: (e: any) => void;
  fileType?: string | null | undefined;
  allowVideo?: boolean | undefined;
};

const UploadImageContainer = ({
  id,
  label,
  description,
  blobUrl,
  errorMessage,
  handleFileChange,
  fileType,
  allowVideo = false,
}: Props) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    const obj = {
      target: {
        files: acceptedFiles,
        name: id,
      },
    };

    handleFileChange(obj);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowVideo
      ? { 'image/*,video/*': ['.jpeg', '.jpg', '.png', '.mp4'] }
      : { 'image/*': ['.jpeg', '.png', '.jpg'] },
  });

  return (
    <div {...getRootProps()}>
      <label
        htmlFor={id}
        className="w-full flex items-center cursor-pointer rounded-md text-primary-600 focus-within:outline-none"
      >
        <div className="mt-2 gap-4 w-full flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 hover:opacity-90 hover:bg-gray-200">
          <div className="text-center">
            {blobUrl ? (
              <div className="flex justify-center">
                {fileType === 'video' ? (
                  <video
                    src={blobUrl}
                    controls
                    className="w-max h-64 object-cover rounded-lg"
                  />
                ) : (
                  <img
                    src={blobUrl}
                    alt="attractor image url"
                    className="h-32 object-contain rounded-lg"
                    style={{
                      width: '100%',
                    }}
                  />
                )}
              </div>
            ) : (
              <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
            )}
            <div className="mt-4 flex text-sm text-center leading-6 text-gray-600">
              <span className="w-full flex justify-center gap-2">
                <p>{label}</p>
              </span>
              <input
                id={id}
                name={id}
                className="sr-only"
                {...getInputProps()}
              />
            </div>
            <p className="mt-1 text-xs leading-5 text-gray-600">
              {isDragActive ? 'Drop file here' : description}
            </p>{' '}
            {errorMessage && (
              <div className="mt-2">
                <ErrorAlert message={errorMessage} />
              </div>
            )}
          </div>
        </div>{' '}
      </label>
    </div>
  );
};

export default UploadImageContainer;
