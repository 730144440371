export const ErrorAlert = ({ message }: { message: string }) => {
  return (
    <div
      className="p-4 mb-4 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <span className="font-medium"></span> {message}
    </div>
  );
};

export const SuccessAlert = ({ message }: { message: string }) => {
  return (
    <div
      className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
      role="alert"
    >
      <span className="font-medium"></span> {message}
    </div>
  );
};
