import { ReactNode } from "react";
import { Columns } from "../UiKit";
import { RedBallLoader } from "./Loading";
export type PageHeaderProps = {
  title: string;
  cssProps?: {};
};

export const PageHeader = (props: PageHeaderProps) => {
  return (
    <div
      style={{
        marginTop: 0,
        fontSize: 26,
        textAlign: "center",
        marginBottom: 16,
        ...props.cssProps,
      }}
    >
      {props.title}
    </div>
  );
};

export const PageSubHeader = (props: PageHeaderProps) => {
  return (
    <div
      style={{
        fontSize: 20,
        textAlign: "center",
        marginTop: 16,
        marginBottom: 16,
        ...props.cssProps,
      }}
    >
      {props.title}
    </div>
  );
};

export const PageSubText = (props: PageHeaderProps) => {
  return (
    <div
      style={{
        fontSize: 18,
        textAlign: "center",
        marginTop: 16,
        marginBottom: 16,
        ...props.cssProps,
      }}
    >
      {props.title}
    </div>
  );
};

export const PageLoader = () => {
  return (
    <Columns
      style={{
        height: 600,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <RedBallLoader />
    </Columns>
  );
};

export type BreadCrumb = {
  name: string;
  route: string;
};
export type PageContainerProps = {
  children: ReactNode;
  breadcrumbs?: BreadCrumb[];
  centerContent?: boolean;
};

export const PageContainer = (props: PageContainerProps) => {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-4 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        {props.children}
      </div>
    </section>
  );
};
